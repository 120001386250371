import React from 'react';
import { Link } from 'react-router-dom';

const BlogItem = () => {
	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];
	return (
		<div className='post-card post-card--layout--grid post-card--size--nl'>
			<div className='post-card__image'>
				<Link to='/blogs/test1'>
					<img src='/img/blog.jpg' alt='' />
				</Link>
			</div>
			<div className='post-card__info'>
				<div className='post-card__category'>
					<Link to='/blogs/test1'>Special Offers</Link>
				</div>
				<div className='post-card__name'>
					<Link to='/blogs/test1'>Blog Title</Link>
				</div>
                <div className='post-card__date'>
                    
					{monthNames[new Date().getMonth()]} {new Date().getDay()},{' '}
					{new Date().getFullYear()}
				</div>
				<div className='post-card__content'>
					In one general sense, philosophy is associated with wisdom,
					intellectual culture and a search for knowledge. In that sense, all
					cultures...
				</div>
				<div className='post-card__read-more'>
					<Link to='/blogs/test1' className='btn btn-secondary btn-sm'>
						Read More
					</Link>
				</div>
			</div>
		</div>
	);
};

export default BlogItem;
