import React from 'react';
import { Link } from 'react-router-dom';

const MobileMenuLinksItem = ({ title, link, children, arrow = true }) => {
	return (
		<li className='mobile-links__item' data-collapse-item>
			<div className='mobile-links__item-title'>
				<Link to={link} className='mobile-links__item-link'>
					{title}
				</Link>{' '}
				{arrow && (
					<button
						className='mobile-links__item-toggle'
						type='button'
						data-collapse-trigger>
						<svg
							className='mobile-links__item-arrow'
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 24 24'
							strokeWidth={1.5}
							stroke='currentColor'
							style={{
								width: '16px',
							}}>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M19.5 8.25l-7.5 7.5-7.5-7.5'
							/>
						</svg>
					</button>
				)}
			</div>
			{children}
		</li>
	);
};

export default MobileMenuLinksItem;
