import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Topbar = () => {
	const [myAcOpen, setMyAcOpen] = useState(false);
	const [currencyOpen, setCurrencyOpen] = useState(false);
	const [langOpen, setLangOpen] = useState(false);
	return (
		<div className='site-header__topbar topbar'>
			<div className='topbar__container container'>
				<div className='topbar__row'>
					<div className='topbar__item topbar__item--link'>
						<Link className='topbar-link' to='/about'>
							About Us
						</Link>
					</div>
					<div className='topbar__item topbar__item--link'>
						<Link className='topbar-link' to='/contact'>
							Contacts
						</Link>
					</div>
					<div className='topbar__item topbar__item--link'>
						<Link className='topbar-link' to='/location'>
							Store Location
						</Link>
					</div>
					<div className='topbar__item topbar__item--link'>
						<Link className='topbar-link' to='/track-order'>
							Track Order
						</Link>
					</div>
					<div className='topbar__item topbar__item--link'>
						<Link className='topbar-link' to='/blogs'>
							Blog
						</Link>
					</div>
					<div className='topbar__spring'></div>
					<div className='topbar__item'>
						<div
							className={`topbar-dropdown ${
								myAcOpen ? 'topbar-dropdown--opened' : ''
							}`}>
							<button
								className='topbar-dropdown__btn'
								type='button'
								onClick={() => setMyAcOpen(!myAcOpen)}>
								My Account{' '}
								<svg width='7px' height='5px'>
									<use href='images/sprite.svg#arrow-rounded-down-7x5'></use>
								</svg>
							</button>
							<div className='topbar-dropdown__body'>
								{/* <!-- .menu --> */}
								<ul className='menu menu--layout--topbar'>
									<li>
										<a href='account.html'>Login</a>
									</li>
									<li>
										<a href='account.html'>Register</a>
									</li>
									<li>
										<a href='/'>Orders</a>
									</li>
									<li>
										<a href='/'>Addresses</a>
									</li>
								</ul>
								{/* <!-- .menu / end --> */}
							</div>
						</div>
					</div>
					<div className='topbar__item'>
						<div
							className={`topbar-dropdown ${
								currencyOpen ? 'topbar-dropdown--opened' : ''
							}`}>
							<button
								className='topbar-dropdown__btn'
								type='button'
								onClick={() => setCurrencyOpen(!currencyOpen)}>
								Currency: <span className='topbar__item-value'>USD</span>{' '}
								<svg width='7px' height='5px'>
									<use href='images/sprite.svg#arrow-rounded-down-7x5'></use>
								</svg>
							</button>
							<div className='topbar-dropdown__body'>
								{/* <!-- .menu --> */}
								<ul className='menu menu--layout--topbar'>
									<li>
										<a href='/'>€ Euro</a>
									</li>
									<li>
										<a href='/'>£ Pound Sterling</a>
									</li>
									<li>
										<a href='/'>$ US Dollar</a>
									</li>
									<li>
										<a href='/'>₽ Russian Ruble</a>
									</li>
								</ul>
								{/* <!-- .menu / end --> */}
							</div>
						</div>
					</div>
					<div className='topbar__item'>
						<div className={`topbar-dropdown ${
								langOpen ? 'topbar-dropdown--opened' : ''
							}`}>
							<button className='topbar-dropdown__btn' type='button' onClick={() => setLangOpen(!langOpen)}>
								Language: <span className='topbar__item-value'>EN</span>{' '}
								<svg width='7px' height='5px'>
									<use href='images/sprite.svg#arrow-rounded-down-7x5'></use>
								</svg>
							</button>
							<div className='topbar-dropdown__body'>
								{/* <!-- .menu --> */}
								<ul className='menu menu--layout--topbar menu--with-icons'>
									<li>
										<a href='/'>
											<div className='menu__icon'>
												<img
													src='/img/language-1.png'
													alt=''
												/>
											</div>
											English
										</a>
									</li>
									<li>
										<a href='/'>
											<div className='menu__icon'>
												<img
													
													src='/img/language-2.png'
													alt=''
												/>
											</div>
											French
										</a>
									</li>
									<li>
										<a href='/'>
											<div className='menu__icon'>
												<img
													src='/img/language-3.png'
													alt=''
												/>
											</div>
											German
										</a>
									</li>
									<li>
										<a href='/'>
											<div className='menu__icon'>
												<img
													src='/img/language-4.png'
													alt=''
												/>
											</div>
											Russian
										</a>
									</li>
									<li>
										<a href='/'>
											<div className='menu__icon'>
												<img
													src='/img/language-5.png'
													alt=''
												/>
											</div>
											Italian
										</a>
									</li>
								</ul>
								{/* <!-- .menu / end --> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Topbar;
