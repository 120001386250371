import React from 'react';

const NavMenu = ({children}) => {
	return (
		<div className='nav-links__menu'>
            <ul className='menu menu--layout--classic'>
                {children}
            </ul>
		</div>
	);
};

export default NavMenu;
