import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ProductDetailsTab = () => {
	const [activeTab, setActiveTab] = useState(1);

	return (
		<div className='product-tabs'>
			<div className='product-tabs__list'>
				<span
					onClick={() => setActiveTab(1)}
					className={`product-tabs__item ${activeTab === 1 ? 'product-tabs__item--active' : ''}`}>
					Description
				</span>{' '}
				<span onClick={() => setActiveTab(2)}
					className={`product-tabs__item ${activeTab === 2 ? 'product-tabs__item--active' : ''}`}>
					Specification
				</span>{' '}
				<span onClick={() => setActiveTab(3)}
					className={`product-tabs__item ${activeTab === 3 ? 'product-tabs__item--active' : ''}`}>
					Reviews
				</span>
			</div>
			<div className='product-tabs__content'>
				<div
					className={`product-tabs__pane ${activeTab === 1 ? 'product-tabs__pane--active' : ''}`}>
					<div className='typography'>
						<h3>Product Full Description</h3>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
							fermentum, diam non iaculis finibus, ipsum arcu sollicitudin
							dolor, ut cursus sapien sem sed purus. Donec vitae fringilla
							tortor, sed fermentum nunc. Suspendisse sodales turpis dolor, at
							rutrum dolor tristique id. Quisque pellentesque ullamcorper felis,
							eget gravida mi elementum a. Maecenas consectetur volutpat ante,
							sit amet molestie urna luctus in. Nulla eget dolor semper urna
							malesuada dictum. Duis eleifend pellentesque dui et finibus.
							Pellentesque dapibus dignissim augue. Etiam odio est, sodales ac
							aliquam id, iaculis eget lacus. Aenean porta, ante vitae suscipit
							pulvinar, purus dui interdum tellus, sed dapibus mi mauris vitae
							tellus.
						</p>
						<h3>Etiam lacus lacus mollis in mattis</h3>
						<p>
							Praesent mattis eget augue ac elementum. Maecenas vel ante ut enim
							mollis accumsan. Vestibulum vel eros at mi suscipit feugiat. Sed
							tortor purus, vulputate et eros a, rhoncus laoreet orci. Proin
							sapien neque, commodo at porta in, vehicula eu elit. Vestibulum
							ante ipsum primis in faucibus orci luctus et ultrices posuere
							cubilia Curae; Curabitur porta vulputate augue, at sollicitudin
							nisl molestie eget.
						</p>
						<p>
							Nunc sollicitudin, nunc id accumsan semper, libero nunc aliquet
							nulla, nec pretium ipsum risus ac neque. Morbi eu facilisis purus.
							Quisque mi tortor, cursus in nulla ut, laoreet commodo quam.
							Pellentesque et ornare sapien. In ac est tempus urna tincidunt
							finibus. Integer erat ipsum, tristique ac lobortis sit amet,
							dapibus sit amet purus. Nam sed lorem nisi. Vestibulum ultrices
							tincidunt turpis, sit amet fringilla odio scelerisque non.
						</p>
					</div>
				</div>
				<div className={`product-tabs__pane ${activeTab === 2 ? 'product-tabs__pane--active' : ''}`}>
					<div className='spec'>
						<h3 className='spec__header'>Specification</h3>
						<div className='spec__section'>
							<h4 className='spec__section-title'>General</h4>
							<div className='spec__row'>
								<div className='spec__name'>Material</div>
								<div className='spec__value'>Aluminium, Plastic</div>
							</div>
							<div className='spec__row'>
								<div className='spec__name'>Engine Type</div>
								<div className='spec__value'>Brushless</div>
							</div>
							<div className='spec__row'>
								<div className='spec__name'>Battery Voltage</div>
								<div className='spec__value'>18 V</div>
							</div>
							<div className='spec__row'>
								<div className='spec__name'>Battery Type</div>
								<div className='spec__value'>Li-lon</div>
							</div>
							<div className='spec__row'>
								<div className='spec__name'>Number of Speeds</div>
								<div className='spec__value'>2</div>
							</div>
							<div className='spec__row'>
								<div className='spec__name'>Charge Time</div>
								<div className='spec__value'>1.08 h</div>
							</div>
							<div className='spec__row'>
								<div className='spec__name'>Weight</div>
								<div className='spec__value'>1.5 kg</div>
							</div>
						</div>
						<div className='spec__section'>
							<h4 className='spec__section-title'>Dimensions</h4>
							<div className='spec__row'>
								<div className='spec__name'>Length</div>
								<div className='spec__value'>99 mm</div>
							</div>
							<div className='spec__row'>
								<div className='spec__name'>Width</div>
								<div className='spec__value'>207 mm</div>
							</div>
							<div className='spec__row'>
								<div className='spec__name'>Height</div>
								<div className='spec__value'>208 mm</div>
							</div>
						</div>
						<div className='spec__disclaimer'>
							Information on technical characteristics, the delivery set, the
							country of manufacture and the appearance of the goods is for
							reference only and is based on the latest information available at
							the time of publication.
						</div>
					</div>
				</div>
				<div className={`product-tabs__pane ${activeTab === 3 ? 'product-tabs__pane--active' : ''}`}>
					<div className='reviews-view'>
						<div className='reviews-view__list'>
							<h3 className='reviews-view__header'>Customer Reviews</h3>
							<div className='reviews-list'>
								<ol className='reviews-list__content'>
									<li className='reviews-list__item'>
										<div className='review'>
											<div className='review__avatar'>
												<img src='images/avatars/avatar-1.jpg' alt='' />
											</div>
											<div className='review__content'>
												<div className='review__author'>Samantha Smith</div>
												<div className='review__rating'>
													<div className='rating'>
														<div className='rating__body'>
															<svg
																className='rating__star rating__star--active'
																width='13px'
																height='12px'>
																<g className='rating__fill'>
																	<use href='images/sprite.svg#star-normal'></use>
																</g>
																<g className='rating__stroke'>
																	<use href='images/sprite.svg#star-normal-stroke'></use>
																</g>
															</svg>
															<div className='rating__star rating__star--only-edge rating__star--active'>
																<div className='rating__fill'>
																	<div className='fake-svg-icon'></div>
																</div>
																<div className='rating__stroke'>
																	<div className='fake-svg-icon'></div>
																</div>
															</div>
															<svg
																className='rating__star rating__star--active'
																width='13px'
																height='12px'>
																<g className='rating__fill'>
																	<use href='images/sprite.svg#star-normal'></use>
																</g>
																<g className='rating__stroke'>
																	<use href='images/sprite.svg#star-normal-stroke'></use>
																</g>
															</svg>
															<div className='rating__star rating__star--only-edge rating__star--active'>
																<div className='rating__fill'>
																	<div className='fake-svg-icon'></div>
																</div>
																<div className='rating__stroke'>
																	<div className='fake-svg-icon'></div>
																</div>
															</div>
															<svg
																className='rating__star rating__star--active'
																width='13px'
																height='12px'>
																<g className='rating__fill'>
																	<use href='images/sprite.svg#star-normal'></use>
																</g>
																<g className='rating__stroke'>
																	<use href='images/sprite.svg#star-normal-stroke'></use>
																</g>
															</svg>
															<div className='rating__star rating__star--only-edge rating__star--active'>
																<div className='rating__fill'>
																	<div className='fake-svg-icon'></div>
																</div>
																<div className='rating__stroke'>
																	<div className='fake-svg-icon'></div>
																</div>
															</div>
															<svg
																className='rating__star rating__star--active'
																width='13px'
																height='12px'>
																<g className='rating__fill'>
																	<use href='images/sprite.svg#star-normal'></use>
																</g>
																<g className='rating__stroke'>
																	<use href='images/sprite.svg#star-normal-stroke'></use>
																</g>
															</svg>
															<div className='rating__star rating__star--only-edge rating__star--active'>
																<div className='rating__fill'>
																	<div className='fake-svg-icon'></div>
																</div>
																<div className='rating__stroke'>
																	<div className='fake-svg-icon'></div>
																</div>
															</div>
															<svg
																className='rating__star'
																width='13px'
																height='12px'>
																<g className='rating__fill'>
																	<use href='images/sprite.svg#star-normal'></use>
																</g>
																<g className='rating__stroke'>
																	<use href='images/sprite.svg#star-normal-stroke'></use>
																</g>
															</svg>
															<div className='rating__star rating__star--only-edge'>
																<div className='rating__fill'>
																	<div className='fake-svg-icon'></div>
																</div>
																<div className='rating__stroke'>
																	<div className='fake-svg-icon'></div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className='review__text'>
													Phasellus id mattis nulla. Mauris velit nisi,
													imperdiet vitae sodales in, maximus ut lectus. Vivamus
													commodo scelerisque lacus, at porttitor dui iaculis
													id. Curabitur imperdiet ultrices fermentum.
												</div>
												<div className='review__date'>27 May, 2018</div>
											</div>
										</div>
									</li>
									<li className='reviews-list__item'>
										<div className='review'>
											<div className='review__avatar'>
												<img src='images/avatars/avatar-2.jpg' alt='' />
											</div>
											<div className='review__content'>
												<div className='review__author'>Adam Taylor</div>
												<div className='review__rating'>
													<div className='rating'>
														<div className='rating__body'>
															<svg
																className='rating__star rating__star--active'
																width='13px'
																height='12px'>
																<g className='rating__fill'>
																	<use href='images/sprite.svg#star-normal'></use>
																</g>
																<g className='rating__stroke'>
																	<use href='images/sprite.svg#star-normal-stroke'></use>
																</g>
															</svg>
															<div className='rating__star rating__star--only-edge rating__star--active'>
																<div className='rating__fill'>
																	<div className='fake-svg-icon'></div>
																</div>
																<div className='rating__stroke'>
																	<div className='fake-svg-icon'></div>
																</div>
															</div>
															<svg
																className='rating__star rating__star--active'
																width='13px'
																height='12px'>
																<g className='rating__fill'>
																	<use href='images/sprite.svg#star-normal'></use>
																</g>
																<g className='rating__stroke'>
																	<use href='images/sprite.svg#star-normal-stroke'></use>
																</g>
															</svg>
															<div className='rating__star rating__star--only-edge rating__star--active'>
																<div className='rating__fill'>
																	<div className='fake-svg-icon'></div>
																</div>
																<div className='rating__stroke'>
																	<div className='fake-svg-icon'></div>
																</div>
															</div>
															<svg
																className='rating__star rating__star--active'
																width='13px'
																height='12px'>
																<g className='rating__fill'>
																	<use href='images/sprite.svg#star-normal'></use>
																</g>
																<g className='rating__stroke'>
																	<use href='images/sprite.svg#star-normal-stroke'></use>
																</g>
															</svg>
															<div className='rating__star rating__star--only-edge rating__star--active'>
																<div className='rating__fill'>
																	<div className='fake-svg-icon'></div>
																</div>
																<div className='rating__stroke'>
																	<div className='fake-svg-icon'></div>
																</div>
															</div>
															<svg
																className='rating__star'
																width='13px'
																height='12px'>
																<g className='rating__fill'>
																	<use href='images/sprite.svg#star-normal'></use>
																</g>
																<g className='rating__stroke'>
																	<use href='images/sprite.svg#star-normal-stroke'></use>
																</g>
															</svg>
															<div className='rating__star rating__star--only-edge'>
																<div className='rating__fill'>
																	<div className='fake-svg-icon'></div>
																</div>
																<div className='rating__stroke'>
																	<div className='fake-svg-icon'></div>
																</div>
															</div>
															<svg
																className='rating__star'
																width='13px'
																height='12px'>
																<g className='rating__fill'>
																	<use href='images/sprite.svg#star-normal'></use>
																</g>
																<g className='rating__stroke'>
																	<use href='images/sprite.svg#star-normal-stroke'></use>
																</g>
															</svg>
															<div className='rating__star rating__star--only-edge'>
																<div className='rating__fill'>
																	<div className='fake-svg-icon'></div>
																</div>
																<div className='rating__stroke'>
																	<div className='fake-svg-icon'></div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className='review__text'>
													Aenean non lorem nisl. Duis tempor sollicitudin orci,
													eget tincidunt ex semper sit amet. Nullam neque justo,
													sodales congue feugiat ac, facilisis a augue. Donec
													tempor sapien et fringilla facilisis. Nam maximus
													consectetur diam. Nulla ut ex mollis, volutpat tellus
													vitae, accumsan ligula.
												</div>
												<div className='review__date'>12 April, 2018</div>
											</div>
										</div>
									</li>
									<li className='reviews-list__item'>
										<div className='review'>
											<div className='review__avatar'>
												<img src='images/avatars/avatar-3.jpg' alt='' />
											</div>
											<div className='review__content'>
												<div className='review__author'>Helena Garcia</div>
												<div className='review__rating'>
													<div className='rating'>
														<div className='rating__body'>
															<svg
																className='rating__star rating__star--active'
																width='13px'
																height='12px'>
																<g className='rating__fill'>
																	<use href='images/sprite.svg#star-normal'></use>
																</g>
																<g className='rating__stroke'>
																	<use href='images/sprite.svg#star-normal-stroke'></use>
																</g>
															</svg>
															<div className='rating__star rating__star--only-edge rating__star--active'>
																<div className='rating__fill'>
																	<div className='fake-svg-icon'></div>
																</div>
																<div className='rating__stroke'>
																	<div className='fake-svg-icon'></div>
																</div>
															</div>
															<svg
																className='rating__star rating__star--active'
																width='13px'
																height='12px'>
																<g className='rating__fill'>
																	<use href='images/sprite.svg#star-normal'></use>
																</g>
																<g className='rating__stroke'>
																	<use href='images/sprite.svg#star-normal-stroke'></use>
																</g>
															</svg>
															<div className='rating__star rating__star--only-edge rating__star--active'>
																<div className='rating__fill'>
																	<div className='fake-svg-icon'></div>
																</div>
																<div className='rating__stroke'>
																	<div className='fake-svg-icon'></div>
																</div>
															</div>
															<svg
																className='rating__star rating__star--active'
																width='13px'
																height='12px'>
																<g className='rating__fill'>
																	<use href='images/sprite.svg#star-normal'></use>
																</g>
																<g className='rating__stroke'>
																	<use href='images/sprite.svg#star-normal-stroke'></use>
																</g>
															</svg>
															<div className='rating__star rating__star--only-edge rating__star--active'>
																<div className='rating__fill'>
																	<div className='fake-svg-icon'></div>
																</div>
																<div className='rating__stroke'>
																	<div className='fake-svg-icon'></div>
																</div>
															</div>
															<svg
																className='rating__star rating__star--active'
																width='13px'
																height='12px'>
																<g className='rating__fill'>
																	<use href='images/sprite.svg#star-normal'></use>
																</g>
																<g className='rating__stroke'>
																	<use href='images/sprite.svg#star-normal-stroke'></use>
																</g>
															</svg>
															<div className='rating__star rating__star--only-edge rating__star--active'>
																<div className='rating__fill'>
																	<div className='fake-svg-icon'></div>
																</div>
																<div className='rating__stroke'>
																	<div className='fake-svg-icon'></div>
																</div>
															</div>
															<svg
																className='rating__star rating__star--active'
																width='13px'
																height='12px'>
																<g className='rating__fill'>
																	<use href='images/sprite.svg#star-normal'></use>
																</g>
																<g className='rating__stroke'>
																	<use href='images/sprite.svg#star-normal-stroke'></use>
																</g>
															</svg>
															<div className='rating__star rating__star--only-edge rating__star--active'>
																<div className='rating__fill'>
																	<div className='fake-svg-icon'></div>
																</div>
																<div className='rating__stroke'>
																	<div className='fake-svg-icon'></div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className='review__text'>
													Duis ac lectus scelerisque quam blandit egestas.
													Pellentesque hendrerit eros laoreet suscipit ultrices.
												</div>
												<div className='review__date'>2 January, 2018</div>
											</div>
										</div>
									</li>
								</ol>
								<div className='reviews-list__pagination'>
									<ul className='pagination justify-content-center'>
										<li className='page-item disabled'>
											<Link
												className='page-link page-link--with-arrow'
												to='/'
												aria-label='Previous'>
												<svg
													className='page-link__arrow page-link__arrow--left'
													aria-hidden='true'
													width='8px'
													height='13px'>
													<use href='images/sprite.svg#arrow-rounded-left-8x13'></use>
												</svg>
											</Link>
										</li>
										<li className='page-item'>
											<Link className='page-link' to='/'>
												1
											</Link>
										</li>
										<li className='page-item active'>
											<Link className='page-link' to='/'>
												2 <span className='sr-only'>(current)</span>
											</Link>
										</li>
										<li className='page-item'>
											<Link className='page-link' to='/'>
												3
											</Link>
										</li>
										<li className='page-item'>
											<Link
												className='page-link page-link--with-arrow'
												to='/'
												aria-label='Next'>
												<svg
													className='page-link__arrow page-link__arrow--right'
													aria-hidden='true'
													width='8px'
													height='13px'>
													<use href='images/sprite.svg#arrow-rounded-right-8x13'></use>
												</svg>
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<form className='reviews-view__form'>
							<h3 className='reviews-view__header'>Write A Review</h3>
							<div className='row'>
								<div className='col-12'>
									<div className='row'>
										<div className='form-group col-md-4'>
											<label htmlFor='review-stars'>Review Stars</label>{' '}
											<select id='review-stars' className='form-control'>
												<option>5 Stars Rating</option>
												<option>4 Stars Rating</option>
												<option>3 Stars Rating</option>
												<option>2 Stars Rating</option>
												<option>1 Stars Rating</option>
											</select>
										</div>
										<div className='form-group col-md-4'>
											<label htmlFor='review-author'>Your Name</label>{' '}
											<input
												type='text'
												className='form-control'
												id='review-author'
												placeholder='Your Name'
											/>
										</div>
										<div className='form-group col-md-4'>
											<label htmlFor='review-email'>Email Address</label>{' '}
											<input
												type='text'
												className='form-control'
												id='review-email'
												placeholder='Email Address'
											/>
										</div>
									</div>
									<div className='form-group'>
										<label htmlFor='review-text'>Your Review</label>
										<textarea
											className='form-control'
											id='review-text'
											rows='6'></textarea>
									</div>
									<div className='form-group mb-0'>
										<button type='submit' className='btn btn-primary btn-lg'>
											Post Your Review
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductDetailsTab;
