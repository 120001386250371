import React from 'react';
import { Link } from 'react-router-dom';

const Promotion = () => {
	return (
		<div className='container pb-5'>
			<Link to='/' className='block-banner__body'>
				<div
					className='block-banner__image block-banner__image--desktop'
					style={{
						backgroundImage: `url('/img/promo.jpg')`,
					}}></div>
				<div
					className='block-banner__image block-banner__image--mobile'
					style={{
						backgroundImage: `url('/img/promo-mobile.jpg')`,
					}}></div>
				<div className='block-banner__title'>
					Hundreds
					<br className='block-banner__mobile-br' /> Hand Tools
				</div>
				<div className='block-banner__text'>
					Hammers, Chisels, Universal Pliers, Nippers, Jigsaws, Saws
				</div>
				<div className='block-banner__button'>
					<Link to='/shop' className='btn btn-primary btn-sm'>
						Shop Now
					</Link>
				</div>
			</Link>
		</div>
	);
};

export default Promotion;
