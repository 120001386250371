import React, { useState } from 'react';
import Breadcumb from '../Common/Breadcumb';
import SingleItem from '../FeaturedProduct/SingleItem';
import MultiRangeSlider from 'multi-range-slider-react';
import Footer from '../Footer/Footer';
import Navigation from '../Navbar/Navigation';
import LatestProductItem from '../ShopPage/LatestProductItem';

const Store = () => {
	const [PFOpen, setPFOpen] = useState(false);
	const [brandOpen, setBrandOpen] = useState(false);
	const [colorOpen, setColorOpen] = useState(false);

	const [minValue, set_minValue] = useState(590);
	const [maxValue, set_maxValue] = useState(1130);
	const handleInput = e => {
		set_minValue(e.minValue);
		set_maxValue(e.maxValue);
	};
	return (
		<>
			<Navigation />
			<Breadcumb />
			<div class='container mb-4'>
				<div class='shop-layout shop-layout--sidebar--start'>
					<div class='shop-layout__sidebar'>
						<div class='block block-sidebar'>
							<div class='block-sidebar__item'>
								<div
									class='widget-filters widget'
									data-collapse
									data-collapse-opened-class='filter--opened'>
									<h4 class='widget__title'>Filters</h4>
									<div class='widget-filters__list'>
										<div class='widget-filters__item'>
											<div
												class={`filter ${PFOpen ? 'filter--opened' : ''}`}
												data-collapse-item>
												<button
													type='button'
													class='filter__title'
													onClick={() => setPFOpen(!PFOpen)}>
													Price{' '}
													<svg class='filter__arrow' width='12px' height='7px'>
														<use href='images/sprite.svg#arrow-rounded-down-12x7'></use>
													</svg>
												</button>
												<div class='filter__body' data-collapse-content>
													<div class='filter__container'>
														<div
															class='filter-price'>
															<MultiRangeSlider
																min={50}
																max={1500}
																step={5}
																ruler={false}
																label={false}
																barInnerColor='#1B68A4'
																minValue={minValue}
																maxValue={maxValue}
																onInput={e => {
																	handleInput(e);
																}}
															/>
															<div class='filter-price__title'>
																Price: $
																<span class='filter-price__min-value'>
																	{minValue}
																</span>{' '}
																– $
																<span class='filter-price__max-value'>
																	{maxValue}
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class='widget-filters__item'>
											<div class={`filter ${brandOpen ? 'filter--opened' : ''}`} data-collapse-item>
												<button
													type='button'
													class='filter__title'
													onClick={() => setBrandOpen(!brandOpen)}
												>
													Brand{' '}
													<svg class='filter__arrow' width='12px' height='7px'>
														<use href='images/sprite.svg#arrow-rounded-down-12x7'></use>
													</svg>
												</button>
												<div class='filter__body' data-collapse-content>
													<div class='filter__container'>
														<div class='filter-list'>
															<div class='filter-list__list'>
																<label class='filter-list__item'>
																	<span class='filter-list__input input-radio'>
																		<span class='input-radio__body'>
																			<input
																				class='input-radio__input'
																				name='filter_radio'
																				type='radio'
																			/>{' '}
																			<span class='input-radio__circle'></span>
																		</span>
																	</span>
																	<span class='filter-list__title'>
																		Wakita{' '}
																	</span>
																	<span class='filter-list__counter'>7</span>
																</label>
																<label class='filter-list__item'>
																	<span class='filter-list__input input-radio'>
																		<span class='input-radio__body'>
																			<input
																				class='input-radio__input'
																				name='filter_radio'
																				type='radio'
																			/>{' '}
																			<span class='input-radio__circle'></span>
																		</span>
																	</span>
																	<span class='filter-list__title'>Zosch </span>
																	<span class='filter-list__counter'>42</span>
																</label>
																<label class='filter-list__item'>
																	<span class='filter-list__input input-radio'>
																		<span class='input-radio__body'>
																			<input
																				class='input-radio__input'
																				name='filter_radio'
																				type='radio'
																			/>{' '}
																			<span class='input-radio__circle'></span>
																		</span>
																	</span>
																	<span class='filter-list__title'>
																		Mitasia{' '}
																	</span>
																	<span class='filter-list__counter'>1</span>
																</label>
																<label class='filter-list__item'>
																	<span class='filter-list__input input-radio'>
																		<span class='input-radio__body'>
																			<input
																				class='input-radio__input'
																				name='filter_radio'
																				type='radio'
																			/>{' '}
																			<span class='input-radio__circle'></span>
																		</span>
																	</span>
																	<span class='filter-list__title'>
																		Metaggo{' '}
																	</span>
																	<span class='filter-list__counter'>25</span>
																</label>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class='widget-filters__item'>
											<div class={`filter ${colorOpen ? 'filter--opened' : ''}`} data-collapse-item>
												<button
													type='button'
													class='filter__title'
													onClick={() => setColorOpen(!colorOpen)}
												>
													Color{' '}
													<svg class='filter__arrow' width='12px' height='7px'>
														<use href='images/sprite.svg#arrow-rounded-down-12x7'></use>
													</svg>
												</button>
												<div class='filter__body' data-collapse-content>
													<div class='filter__container'>
														<div class='filter-color'>
															<div class='filter-color__list'>
																<label class='filter-color__item'>
																	<span
																		class='filter-color__check input-check-color input-check-color--white'
																		style={{ color: '#fff' }}>
																		<label class='input-check-color__body'>
																			<input
																				class='input-check-color__input'
																				type='checkbox'
																			/>{' '}
																			<span class='input-check-color__box'></span>
																			<svg
																				class='input-check-color__icon'
																				width='12px'
																				height='9px'>
																				<use href='images/sprite.svg#check-12x9'></use>
																			</svg>{' '}
																			<span class='input-check-color__stick'></span>
																		</label>
																	</span>
																</label>
																<label class='filter-color__item'>
																	<span
																		class='filter-color__check input-check-color input-check-color--light'
																		style={{ color: '#d9d9d9' }}>
																		<label class='input-check-color__body'>
																			<input
																				class='input-check-color__input'
																				type='checkbox'
																			/>{' '}
																			<span class='input-check-color__box'></span>
																			<svg
																				class='input-check-color__icon'
																				width='12px'
																				height='9px'>
																				<use href='images/sprite.svg#check-12x9'></use>
																			</svg>{' '}
																			<span class='input-check-color__stick'></span>
																		</label>
																	</span>
																</label>
																<label class='filter-color__item'>
																	<span
																		class='filter-color__check input-check-color'
																		style={{ color: '#b3b3b3' }}>
																		<label class='input-check-color__body'>
																			<input
																				class='input-check-color__input'
																				type='checkbox'
																			/>{' '}
																			<span class='input-check-color__box'></span>
																			<svg
																				class='input-check-color__icon'
																				width='12px'
																				height='9px'>
																				<use href='images/sprite.svg#check-12x9'></use>
																			</svg>{' '}
																			<span class='input-check-color__stick'></span>
																		</label>
																	</span>
																</label>
																<label class='filter-color__item'>
																	<span
																		class='filter-color__check input-check-color'
																		style={{ color: '#808080' }}>
																		<label class='input-check-color__body'>
																			<input
																				class='input-check-color__input'
																				type='checkbox'
																			/>{' '}
																			<span class='input-check-color__box'></span>
																			<svg
																				class='input-check-color__icon'
																				width='12px'
																				height='9px'>
																				<use href='images/sprite.svg#check-12x9'></use>
																			</svg>{' '}
																			<span class='input-check-color__stick'></span>
																		</label>
																	</span>
																</label>
																<label class='filter-color__item'>
																	<span
																		class='filter-color__check input-check-color'
																		style={{ color: '#666' }}>
																		<label class='input-check-color__body'>
																			<input
																				class='input-check-color__input'
																				type='checkbox'
																			/>{' '}
																			<span class='input-check-color__box'></span>
																			<svg
																				class='input-check-color__icon'
																				width='12px'
																				height='9px'>
																				<use href='images/sprite.svg#check-12x9'></use>
																			</svg>{' '}
																			<span class='input-check-color__stick'></span>
																		</label>
																	</span>
																</label>
																<label class='filter-color__item'>
																	<span
																		class='filter-color__check input-check-color'
																		style={{ color: '#4d4d4d' }}>
																		<label class='input-check-color__body'>
																			<input
																				class='input-check-color__input'
																				type='checkbox'
																			/>{' '}
																			<span class='input-check-color__box'></span>
																			<svg
																				class='input-check-color__icon'
																				width='12px'
																				height='9px'>
																				<use href='images/sprite.svg#check-12x9'></use>
																			</svg>{' '}
																			<span class='input-check-color__stick'></span>
																		</label>
																	</span>
																</label>
																<label class='filter-color__item'>
																	<span
																		class='filter-color__check input-check-color'
																		style={{ color: '#262626' }}>
																		<label class='input-check-color__body'>
																			<input
																				class='input-check-color__input'
																				type='checkbox'
																			/>{' '}
																			<span class='input-check-color__box'></span>
																			<svg
																				class='input-check-color__icon'
																				width='12px'
																				height='9px'>
																				<use href='images/sprite.svg#check-12x9'></use>
																			</svg>{' '}
																			<span class='input-check-color__stick'></span>
																		</label>
																	</span>
																</label>
																<label class='filter-color__item'>
																	<span
																		class='filter-color__check input-check-color'
																		style={{ color: '#ff4040' }}>
																		<label class='input-check-color__body'>
																			<input
																				class='input-check-color__input'
																				type='checkbox'
																				checked='checked'
																			/>{' '}
																			<span class='input-check-color__box'></span>
																			<svg
																				class='input-check-color__icon'
																				width='12px'
																				height='9px'>
																				<use href='images/sprite.svg#check-12x9'></use>
																			</svg>{' '}
																			<span class='input-check-color__stick'></span>
																		</label>
																	</span>
																</label>
																<label class='filter-color__item'>
																	<span
																		class='filter-color__check input-check-color'
																		style={{ color: '#ff8126' }}>
																		<label class='input-check-color__body'>
																			<input
																				class='input-check-color__input'
																				type='checkbox'
																			/>{' '}
																			<span class='input-check-color__box'></span>
																			<svg
																				class='input-check-color__icon'
																				width='12px'
																				height='9px'>
																				<use href='images/sprite.svg#check-12x9'></use>
																			</svg>{' '}
																			<span class='input-check-color__stick'></span>
																		</label>
																	</span>
																</label>
																<label class='filter-color__item'>
																	<span
																		class='filter-color__check input-check-color input-check-color--light'
																		style={{ color: '#ffd440' }}>
																		<label class='input-check-color__body'>
																			<input
																				class='input-check-color__input'
																				type='checkbox'
																			/>{' '}
																			<span class='input-check-color__box'></span>
																			<svg
																				class='input-check-color__icon'
																				width='12px'
																				height='9px'>
																				<use href='images/sprite.svg#check-12x9'></use>
																			</svg>{' '}
																			<span class='input-check-color__stick'></span>
																		</label>
																	</span>
																</label>
																<label class='filter-color__item'>
																	<span
																		class='filter-color__check input-check-color input-check-color--light'
																		style={{ color: '#becc1f' }}>
																		<label class='input-check-color__body'>
																			<input
																				class='input-check-color__input'
																				type='checkbox'
																			/>{' '}
																			<span class='input-check-color__box'></span>
																			<svg
																				class='input-check-color__icon'
																				width='12px'
																				height='9px'>
																				<use href='images/sprite.svg#check-12x9'></use>
																			</svg>{' '}
																			<span class='input-check-color__stick'></span>
																		</label>
																	</span>
																</label>
																<label class='filter-color__item'>
																	<span
																		class='filter-color__check input-check-color'
																		style={{ color: '#8fcc14' }}>
																		<label class='input-check-color__body'>
																			<input
																				class='input-check-color__input'
																				type='checkbox'
																				checked='checked'
																			/>{' '}
																			<span class='input-check-color__box'></span>
																			<svg
																				class='input-check-color__icon'
																				width='12px'
																				height='9px'>
																				<use href='images/sprite.svg#check-12x9'></use>
																			</svg>{' '}
																			<span class='input-check-color__stick'></span>
																		</label>
																	</span>
																</label>
																<label class='filter-color__item'>
																	<span
																		class='filter-color__check input-check-color'
																		style={{ color: '#47cc5e' }}>
																		<label class='input-check-color__body'>
																			<input
																				class='input-check-color__input'
																				type='checkbox'
																			/>{' '}
																			<span class='input-check-color__box'></span>
																			<svg
																				class='input-check-color__icon'
																				width='12px'
																				height='9px'>
																				<use href='images/sprite.svg#check-12x9'></use>
																			</svg>{' '}
																			<span class='input-check-color__stick'></span>
																		</label>
																	</span>
																</label>
																<label class='filter-color__item'>
																	<span
																		class='filter-color__check input-check-color'
																		style={{ color: '#47cca0' }}>
																		<label class='input-check-color__body'>
																			<input
																				class='input-check-color__input'
																				type='checkbox'
																			/>{' '}
																			<span class='input-check-color__box'></span>
																			<svg
																				class='input-check-color__icon'
																				width='12px'
																				height='9px'>
																				<use href='images/sprite.svg#check-12x9'></use>
																			</svg>{' '}
																			<span class='input-check-color__stick'></span>
																		</label>
																	</span>
																</label>
																<label class='filter-color__item'>
																	<span
																		class='filter-color__check input-check-color'
																		style={{ color: '#47cccc' }}>
																		<label class='input-check-color__body'>
																			<input
																				class='input-check-color__input'
																				type='checkbox'
																			/>{' '}
																			<span class='input-check-color__box'></span>
																			<svg
																				class='input-check-color__icon'
																				width='12px'
																				height='9px'>
																				<use href='images/sprite.svg#check-12x9'></use>
																			</svg>{' '}
																			<span class='input-check-color__stick'></span>
																		</label>
																	</span>
																</label>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class='widget-filters__actions d-flex'>
										<button class='btn btn-primary btn-sm'>Filter</button>{' '}
										<button class='btn btn-secondary btn-sm ml-2'>Reset</button>
									</div>
								</div>
							</div>
							<div class='block-sidebar__item d-none d-lg-block'>
								<div class='widget-products widget'>
									<h4 class='widget__title'>Latest Products</h4>
									<div class='widget-products__list'>
										<LatestProductItem />
										<LatestProductItem />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class='shop-layout__content'>
						<div class='block'>
							<div class='products-view'>
								<div class='products-view__options'>
									<div class='view-options'>
										<div class='view-options__legend'>
											Showing 6 of 98 products
										</div>
										<div class='view-options__divider'></div>
										<div class='view-options__control'>
											<label for=''>Sort By</label>
											<div>
												<select
													class='form-control form-control-sm'
													name=''
													id=''>
													<option value=''>Default</option>
													<option value=''>Name (A-Z)</option>
												</select>
											</div>
										</div>
										<div class='view-options__control'>
											<label for=''>Show</label>
											<div>
												<select
													class='form-control form-control-sm'
													name=''
													id=''>
													<option value=''>12</option>
													<option value=''>24</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<div
									class='products-view__list products-list'
									data-layout='grid-3-sidebar'
									data-with-features='false'>
									<div className='products-list__body'>
										<div className='products-list__item'>
											<SingleItem
												title='Dummy Product 1'
												img='https://static-01.daraz.com.bd/p/0addc405b133e6559b7a17c99be907d9.jpg'
											/>
										</div>
										<div className='products-list__item'>
											<SingleItem
												title='Dummy Product 2'
												img='https://static-01.daraz.com.bd/p/123fff165d5f25a3eae038b90d22722d.jpg'
												isHot={true}
											/>
										</div>
										<div className='products-list__item'>
											<SingleItem
												isSale={true}
												title='Dummy Product 3'
												img='https://static-01.daraz.com.bd/p/0b2cfed1f6048d9f98a5772d882fd99f.jpg'
											/>
										</div>
										<div className='products-list__item'>
											<SingleItem
												title='Dummy Product 4'
												img='https://static-01.daraz.com.bd/p/0ef12d2214da07642c74b087e3d074db.jpg'
												isHot={true}
											/>
										</div>
										<div className='products-list__item'>
											<SingleItem
												title='Dummy Product 5'
												img='https://static-01.daraz.com.bd/p/cf664bf021d2b225d73dadf8fb726115.jpg'
												isSale={true}
											/>
										</div>
										<div className='products-list__item'>
											<SingleItem
												title='Dummy Product 1'
												img='https://static-01.daraz.com.bd/p/a63bdb0413cb7ca90d5bd0e439515f3e.jpg'
											/>
										</div>
										<div className='products-list__item'>
											<SingleItem
												title='Dummy Product 2'
												img='https://static-01.daraz.com.bd/p/9324630ab12cd615267d23f1a61e898f.jpg'
												isHot={true}
											/>
										</div>
										<div className='products-list__item'>
											<SingleItem
												isSale={true}
												title='Dummy Product 3'
												img='https://static-01.daraz.com.bd/p/29c19ca52d0716d9df6b9bf719c4abc7.jpg'
											/>
										</div>
										<div className='products-list__item'>
											<SingleItem
												title='Dummy Product 4'
												img='https://static-01.daraz.com.bd/p/b7fccf517415baab937db73872b25056.jpg'
												isHot={true}
											/>
										</div>
										<div className='products-list__item'>
											<SingleItem
												title='Dummy Product 5'
												img='https://static-01.daraz.com.bd/p/b6475087df47dbf6c1bd00d4b430d3af.jpg'
												isSale={true}
											/>
										</div>
									</div>
								</div>
								<div class='products-view__pagination'>
									<ul class='pagination justify-content-center'>
										<li class='page-item disabled'>
											<a
												class='page-link page-link--with-arrow'
												href='/'
												aria-label='Previous'>
												<svg
													class='page-link__arrow page-link__arrow--left'
													aria-hidden='true'
													width='8px'
													height='13px'>
													<use href='images/sprite.svg#arrow-rounded-left-8x13'></use>
												</svg>
											</a>
										</li>
										<li class='page-item'>
											<a class='page-link' href='/'>
												1
											</a>
										</li>
										<li class='page-item active'>
											<a class='page-link' href='/'>
												2 <span class='sr-only'>(current)</span>
											</a>
										</li>
										<li class='page-item'>
											<a class='page-link' href='/'>
												3
											</a>
										</li>
										<li class='page-item'>
											<a
												class='page-link page-link--with-arrow'
												href='/'
												aria-label='Next'>
												<svg
													class='page-link__arrow page-link__arrow--right'
													aria-hidden='true'
													width='8px'
													height='13px'>
													<use href='images/sprite.svg#arrow-rounded-right-8x13'></use>
												</svg>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Store;
