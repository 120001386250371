import React from 'react';
import { Link } from 'react-router-dom';

const BlogSidebar = () => {
	return (
		<div className='block block-sidebar block-sidebar--position--end'>
			<div className='block-sidebar__item'>
				<div className='widget-search'>
					<form className='widget-search__body'>
						<input
							className='widget-search__input'
							placeholder='Blog search...'
							type='text'
							autocomplete='off'
							spellcheck='false'
						/>{' '}
						<button
							className='widget-search__button text-secondary'
							type='submit'>
							<i className='fas fa-search'></i>
						</button>
					</form>
				</div>
			</div>
			<div className='block-sidebar__item'>
				<div className='widget-aboutus widget'>
					<h4 className='widget__title'>About Blog</h4>
					<div className='widget-aboutus__text'>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
						tincidunt, erat in malesuada aliquam, est erat faucibus purus, eget
						viverra nulla sem vitae neque. Quisque id sodales libero.
					</div>
					<div className='widget-aboutus__socials'>
						<ul>
							<li>
								<a
									className='widget-aboutus__link widget-aboutus__link--rss'
									href='https://sulaimanhosain.me/'
									target='_blank'
									rel='noreferrer'>
									<i className='widget-social__icon fas fa-rss'></i>
								</a>
							</li>
							<li>
								<a
									className='widget-aboutus__link widget-aboutus__link--youtube'
									href='https://sulaimanhosain.me/'
									target='_blank'
									rel='noreferrer'>
									<i className='widget-aboutus__icon fab fa-youtube'></i>
								</a>
							</li>
							<li>
								<a
									className='widget-aboutus__link widget-aboutus__link--facebook'
									href='https://sulaimanhosain.me/'
									target='_blank'
									rel='noreferrer'>
									<i className='widget-aboutus__icon fab fa-facebook-f'></i>
								</a>
							</li>
							<li>
								<a
									className='widget-aboutus__link widget-aboutus__link--twitter'
									href='https://sulaimanhosain.me/'
									target='_blank'
									rel='noreferrer'>
									<i className='widget-aboutus__icon fab fa-twitter'></i>
								</a>
							</li>
							<li>
								<a
									className='widget-aboutus__link widget-aboutus__link--instagram'
									href='https://sulaimanhosain.me/'
									target='_blank'
									rel='noreferrer'>
									<i className='widget-aboutus__icon fab fa-instagram'></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className='block-sidebar__item'>
				<div className='widget-categories widget-categories--location--blog widget'>
					<h4 className='widget__title'>Categories</h4>
					<ul
						className='widget-categories__list'
						data-collapse
						data-collapse-opened-className='widget-categories__item--open'>
						<li className='widget-categories__item' data-collapse-item>
							<div className='widget-categories__row'>
								<Link to='/'>
									<i className='widget-categories__arrow fas fa-angle-right'></i>
									Latest News
								</Link>
							</div>
						</li>
						<li className='widget-categories__item' data-collapse-item>
							<div className='widget-categories__row'>
								<Link to='/'>
									<i className='widget-categories__arrow fas fa-angle-right'></i>
									Special Offers{' '}
								</Link>
							</div>
						</li>
						<li className='widget-categories__item' data-collapse-item>
							<div className='widget-categories__row'>
								<Link to='/'>
                                <i className='widget-categories__arrow fas fa-angle-right'></i>
									New Arrivals
								</Link>
							</div>
						</li>
						<li className='widget-categories__item' data-collapse-item>
							<div className='widget-categories__row'>
								<Link to='/'>
                                <i className='widget-categories__arrow fas fa-angle-right'></i>
									Reviews
								</Link>
							</div>
						</li>
						<li className='widget-categories__item' data-collapse-item>
							<div className='widget-categories__row'>
								<Link to='/'>
                                <i className='widget-categories__arrow fas fa-angle-right'></i>
									Drills and Mixers
								</Link>
							</div>
						</li>
						<li className='widget-categories__item' data-collapse-item>
							<div className='widget-categories__row'>
								<Link to='/'>
                                <i className='widget-categories__arrow fas fa-angle-right'></i>
									Cordless Screwdrivers
								</Link>
							</div>
						</li>
						<li className='widget-categories__item' data-collapse-item>
							<div className='widget-categories__row'>
								<Link to='/'>
                                <i className='widget-categories__arrow fas fa-angle-right'></i>
									Screwdrivers
								</Link>
							</div>
						</li>
						<li className='widget-categories__item' data-collapse-item>
							<div className='widget-categories__row'>
								<Link to='/'>
                                <i className='widget-categories__arrow fas fa-angle-right'></i>
									Wrenches
								</Link>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div className='block-sidebar__item'>
				<div className='widget-newsletter widget'>
					<h4 className='widget-newsletter__title'>Our Newsletter</h4>
					<div className='widget-newsletter__text'>
						Phasellus eleifend sapien felis, at sollicitudin arcu semper mattis.
						Mauris quis mi quis ipsum tristique lobortis. Nulla vitae est
						blandit rutrum.
					</div>
					<form className='widget-newsletter__form' action='#'>
						<label for='widget-newsletter-email' className='sr-only'>
							Email Address
						</label>{' '}
						<input
							id='widget-newsletter-email'
							type='text'
							className='form-control'
							placeholder='Email Address'
						/>{' '}
						<button type='submit' className='btn btn-primary mt-3'>
							Subscribe
						</button>
					</form>
				</div>
			</div>
			<div className='block-sidebar__item'>
				<div className='widget-tags widget'>
					<h4 className='widget__title'>Tags Cloud</h4>
					<div className='tags tags--lg'>
						<div className='tags__list'>
							<Link to='/'>Promotion</Link> <Link to='/'>Power Tool</Link>{' '}
							<Link to='/'>New Arrivals</Link> <Link to='/'>Screwdriver</Link>{' '}
							<Link to='/'>Wrench</Link> <Link to='/'>Mounts</Link>{' '}
							<Link to='/'>Electrodes</Link> <Link to='/'>Chainsaws</Link>{' '}
							<Link to='/'>Manometers</Link> <Link to='/'>Nails</Link>
							<Link to='/'>Air Guns</Link> <Link to='/'>Cutting Discs</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BlogSidebar;
