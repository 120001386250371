import React from 'react';
import ReactOwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';

const Hero = () => {
	return (
		<>
			<div className='block-slideshow block-slideshow--layout--with-departments block'>
				<div className='container'>
					<div className='row'>
						<div className='col-12 col-lg-9 offset-lg-3'>
							<div className='block-slideshow__body'>
								<ReactOwlCarousel items={1}>
									<Link className='block-slideshow__slide' to='/'>
										<div
											className='block-slideshow__slide-image block-slideshow__slide-image--desktop'
											style={{
												backgroundImage: `url('/img/slide-1.jpg')`,
											}}></div>

										<div
											className='block-slideshow__slide-image block-slideshow__slide-image--mobile'
											style={{
												backgroundImage: `url('/img/slide-1-mobile.jpg')`,
											}}></div>

										<div className='block-slideshow__slide-content'>
											<div className='block-slideshow__slide-title'>
												Big choice of
												<br />
												Girls Fashion
											</div>
											<div className='block-slideshow__slide-text'>
												Lorem ipsum dolor sit amet, consectetur adipiscing elit.
												<br />
												Etiam pharetra laoreet dui quis molestie.
											</div>
											<div className='block-slideshow__slide-button'>
												<Link to='/shop' className='btn btn-primary btn-lg'>
													Shop Now
												</Link>
											</div>
										</div>
									</Link>
									<a className='block-slideshow__slide' href='/'>
										<div
											className='block-slideshow__slide-image block-slideshow__slide-image--desktop'
											style={{
												backgroundImage: `url('/img/slide-2.jpg')`,
											}}></div>
										<div
											className='block-slideshow__slide-image block-slideshow__slide-image--mobile'
											style={{
												backgroundImage: `url('/img/slide-2-mobile.jpg')`,
											}}></div>
										<div className='block-slideshow__slide-content'>
											<div className='block-slideshow__slide-title'>
												Our Latest
												<br />
												Collections
											</div>
											<div className='block-slideshow__slide-text'>
												Lorem ipsum dolor sit amet, consectetur adipiscing elit.
												<br />
												Etiam pharetra laoreet dui quis molestie.
											</div>
											<div className='block-slideshow__slide-button'>
												<Link to='/shop' className='btn btn-primary btn-lg'>
													Shop Now
												</Link>
											</div>
										</div>
									</a>
								</ReactOwlCarousel>
								{/* <div className='owl-carousel owl-loaded owl-drag'>
									<Link className='block-slideshow__slide' to='/'>
										<div
											className='block-slideshow__slide-image block-slideshow__slide-image--desktop'
											style={{
												backgroundImage: `url('/img/slide-1.jpg')`,
											}}></div>

										<div
											className='block-slideshow__slide-image block-slideshow__slide-image--mobile'
											style={{
												backgroundImage: `url('/img/slide-1-mobile.jpg')`,
											}}></div>

										<div className='block-slideshow__slide-content'>
											<div className='block-slideshow__slide-title'>
												Big choice of
												<br />
												Girls Fashion
											</div>
											<div className='block-slideshow__slide-text'>
												Lorem ipsum dolor sit amet, consectetur adipiscing elit.
												<br />
												Etiam pharetra laoreet dui quis molestie.
											</div>
											<div className='block-slideshow__slide-button'>
												<span className='btn btn-primary btn-lg'>Shop Now</span>
											</div>
										</div>
									</Link>
									<a className='block-slideshow__slide' href='/'>
										<div
											className='block-slideshow__slide-image block-slideshow__slide-image--desktop'
											style={{
												backgroundImage: `url('/img/slide-2.jpg')`,
											}}></div>
										<div
											className='block-slideshow__slide-image block-slideshow__slide-image--mobile'
											style={{
												backgroundImage: `url('/img/slide-2-mobile.jpg')`,
											}}></div>
										<div className='block-slideshow__slide-content'>
											<div className='block-slideshow__slide-title'>
												Our Latest
												<br />
												Collections
											</div>
											<div className='block-slideshow__slide-text'>
												Lorem ipsum dolor sit amet, consectetur adipiscing elit.
												<br />
												Etiam pharetra laoreet dui quis molestie.
											</div>
											<div className='block-slideshow__slide-button'>
												<span className='btn btn-primary btn-lg'>Shop Now</span>
											</div>
										</div>
									</a>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='block block-features block-features--layout--classic'>
				<div className='container'>
					<div className='block-features__list'>
						<div className='block-features__item'>
							<div className='block-features__icon'>
								<img
									src='/img/free-shipping.svg'
									style={{
										width: '50px',
									}}
									alt=''
								/>
							</div>
							<div className='block-features__content'>
								<div className='block-features__title'>Free Shipping</div>
								<div className='block-features__subtitle'>
									For orders from $50
								</div>
							</div>
						</div>
						<div className='block-features__divider'></div>
						<div className='block-features__item'>
							<div className='block-features__icon'>
								<img
									src='/img/support.svg'
									style={{
										width: '50px',
									}}
									alt=''
								/>
							</div>
							<div className='block-features__content'>
								<div className='block-features__title'>Support 24/7</div>
								<div className='block-features__subtitle'>Call us anytime</div>
							</div>
						</div>
						<div className='block-features__divider'></div>
						<div className='block-features__item'>
							<div className='block-features__icon'>
								<img
									src='/img/privacy.svg'
									style={{
										width: '50px',
									}}
									alt=''
								/>
							</div>
							<div className='block-features__content'>
								<div className='block-features__title'>100% Safety</div>
								<div className='block-features__subtitle'>
									Only secure payments
								</div>
							</div>
						</div>
						<div className='block-features__divider'></div>
						<div className='block-features__item'>
							<div className='block-features__icon'>
								<img
									src='/img/offer.svg'
									style={{
										width: '50px',
									}}
									alt=''
								/>
							</div>
							<div className='block-features__content'>
								<div className='block-features__title'>Hot Offers</div>
								<div className='block-features__subtitle'>
									Discounts up to 90%
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Hero;
