import React from 'react';
import { Link } from 'react-router-dom';

const SubMenuLink = ({ title, link, children }) => {
	return (
		<li className='nav-links__item nav-links__item--with-submenu'>
			<Link to={link}>
                <span>{ title }</span>
			</Link>
			{children}
		</li>
	);
};

export default SubMenuLink;
