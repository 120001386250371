import React from 'react';
import { Link } from 'react-router-dom';

const DropCatProduct = () => {
	return (
		<>
			<div className='dropcart__product'>
				<div className='dropcart__product-image'>
					<Link to='/product/cart_item_1122'>
						<img
							src='https://static-01.daraz.com.bd/p/0b2cfed1f6048d9f98a5772d882fd99f.jpg'
							alt=''
						/>
					</Link>
				</div>
				<div className='dropcart__product-info'>
					<div className='dropcart__product-name'>
						<Link to='/product/cart_item_1122'>
							Electric Planer Brandix KL370090G 300 Watts
						</Link>
					</div>
					<ul className='dropcart__product-options'>
						<li>Color: Yellow</li>
						<li>Material: Aluminium</li>
					</ul>
					<div className='dropcart__product-meta'>
						<span className='dropcart__product-quantity'>2</span> x{' '}
						<span className='dropcart__product-price'>$699.00</span>
					</div>
				</div>
				<button
					type='button'
					className='dropcart__product-remove btn btn-light btn-sm btn-svg-icon'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 24 24'
						strokeWidth={1.5}
						stroke='currentColor'
						style={{ width: '14px' }}>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							d='M6 18L18 6M6 6l12 12'
						/>
					</svg>
				</button>
			</div>
		</>
	);
};

export default DropCatProduct;
