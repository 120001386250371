import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
	return (
		<footer className='site__footer'>
			<div className='site-footer'>
				<div className='container'>
					<div className='site-footer__widgets'>
						<div className='row'>
							<div className='col-12 col-md-6 col-lg-4'>
								<div className='site-footer__widget footer-contacts'>
									<h5 className='footer-contacts__title'>Contact Us</h5>
									<div className='footer-contacts__text'>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
										Integer in feugiat lorem. Pellentque ac placerat tellus.
									</div>
									<ul className='footer-contacts__contacts'>
										<li>
											<i className='footer-contacts__icon fas fa-globe-americas'></i>{' '}
											7/a Sat Masjid Road, Dhandmondi, Dhaka - 1156
										</li>
										<li>
											<i className='footer-contacts__icon far fa-envelope'></i>{' '}
											icicle@example.com
										</li>
										<li>
											<i className='footer-contacts__icon fas fa-mobile-alt'></i>{' '}
											(880) 123-4567-890, (880) 123-4567-890
										</li>
										<li>
											<i className='footer-contacts__icon far fa-clock'></i> Mon-Sat
											10:00pm - 7:00pm
										</li>
									</ul>
								</div>
							</div>
							<div className='col-6 col-md-3 col-lg-2'>
								<div className='site-footer__widget footer-links'>
									<h5 className='footer-links__title'>Information</h5>
									<ul className='footer-links__list'>
										<li className='footer-links__item'>
											<Link to='/' className='footer-links__link'>
												About Us
											</Link>
										</li>
										<li className='footer-links__item'>
											<Link to='/' className='footer-links__link'>
												Delivery Information
											</Link>
										</li>
										<li className='footer-links__item'>
											<Link to='/' className='footer-links__link'>
												Privacy Policy
											</Link>
										</li>
										<li className='footer-links__item'>
											<Link to='/' className='footer-links__link'>
												Brands
											</Link>
										</li>
										<li className='footer-links__item'>
											<Link to='/' className='footer-links__link'>
												Contact Us
											</Link>
										</li>
										<li className='footer-links__item'>
											<Link to='/' className='footer-links__link'>
												Returns
											</Link>
										</li>
										<li className='footer-links__item'>
											<Link to='/' className='footer-links__link'>
												Site Map
											</Link>
										</li>
									</ul>
								</div>
							</div>
							<div className='col-6 col-md-3 col-lg-2'>
								<div className='site-footer__widget footer-links'>
									<h5 className='footer-links__title'>My Account</h5>
									<ul className='footer-links__list'>
										<li className='footer-links__item'>
											<Link to='/' className='footer-links__link'>
												Store Location
											</Link>
										</li>
										<li className='footer-links__item'>
											<Link to='/' className='footer-links__link'>
												Order History
											</Link>
										</li>
										<li className='footer-links__item'>
											<Link to='/' className='footer-links__link'>
												Wish List
											</Link>
										</li>
										<li className='footer-links__item'>
											<Link to='/' className='footer-links__link'>
												Newsletter
											</Link>
										</li>
										<li className='footer-links__item'>
											<Link to='/' className='footer-links__link'>
												Specials
											</Link>
										</li>
										<li className='footer-links__item'>
											<Link to='/' className='footer-links__link'>
												Gift Certificates
											</Link>
										</li>
										<li className='footer-links__item'>
											<Link to='/' className='footer-links__link'>
												Affiliate
											</Link>
										</li>
									</ul>
								</div>
							</div>
							<div className='col-12 col-md-12 col-lg-4'>
								<div className='site-footer__widget footer-newsletter'>
									<h5 className='footer-newsletter__title'>Newsletter</h5>
									<div className='footer-newsletter__text'>
										Praesent pellentesque volutpat ex, vitae auctor lorem
										pulvinar mollis felis at lacinia.
									</div>
									<form action='#' className='footer-newsletter__form'>
										<label className='sr-only' htmlFor='footer-newsletter-address'>
											Email Address
										</label>{' '}
										<input
											type='text'
											className='footer-newsletter__form-input form-control'
											id='footer-newsletter-address'
											placeholder='Email Address...'
										/>{' '}
										<button className='footer-newsletter__form-button btn btn-primary'>
											Subscribe
										</button>
									</form>
									<div className='footer-newsletter__text footer-newsletter__text--social'>
										Follow us on social networks
									</div>
									<ul className='footer-newsletter__social-links'>
										<li className='footer-newsletter__social-link footer-newsletter__social-link--facebook'>
											<a href='/' target='_blank'>
												<i className='fab fa-facebook-f'></i>
											</a>
										</li>
										<li className='footer-newsletter__social-link footer-newsletter__social-link--twitter'>
											<a href='/' target='_blank'>
												<i className='fab fa-twitter'></i>
											</a>
										</li>
										<li className='footer-newsletter__social-link footer-newsletter__social-link--youtube'>
											<a href='/' target='_blank'>
												<i className='fab fa-youtube'></i>
											</a>
										</li>
										<li className='footer-newsletter__social-link footer-newsletter__social-link--instagram'>
											<a href='/' target='_blank'>
												<i className='fab fa-instagram'></i>
											</a>
										</li>
										<li className='footer-newsletter__social-link footer-newsletter__social-link--rss'>
											<a href='/' target='_blank'>
												<i className='fas fa-rss'></i>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className='site-footer__bottom'>
						<div className='site-footer__copyright'>
							&copy; 2022 <Link to='/' >ICICLE Commerce</Link>
						</div>
						<div className='site-footer__payments'>
							<img src='/img/payments.png' alt='' />
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
