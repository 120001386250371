import React from 'react';
import { Link } from 'react-router-dom';
import Breadcumb from '../Common/Breadcumb';
import Footer from '../Footer/Footer';
import Navigation from '../Navbar/Navigation';

const Cart = () => {
	return (
		<>
			<Navigation />
			<Breadcumb />
			<div className='container mb-4'>
				<table className='cart__table cart-table'>
					<thead className='cart-table__head'>
						<tr className='cart-table__row'>
							<th className='cart-table__column cart-table__column--image'>
								Image
							</th>
							<th className='cart-table__column cart-table__column--product'>
								Product
							</th>
							<th className='cart-table__column cart-table__column--price'>
								Price
							</th>
							<th className='cart-table__column cart-table__column--quantity'>
								Quantity
							</th>
							<th className='cart-table__column cart-table__column--total'>
								Total
							</th>
							<th className='cart-table__column cart-table__column--remove'></th>
						</tr>
					</thead>
					<tbody className='cart-table__body'>
						<tr className='cart-table__row'>
							<td className='cart-table__column cart-table__column--image'>
								<Link to='/product/sample_product_0012'>
									<img
										src='https://static-01.daraz.com.bd/p/0b2cfed1f6048d9f98a5772d882fd99f.jpg'
										alt=''
									/>
								</Link>
							</td>
							<td className='cart-table__column cart-table__column--product'>
								<Link
									to='/product/sample_product_0025'
									className='cart-table__product-name'>
									Electric Planer Brandix KL370090G 300 Watts
								</Link>
								<ul className='cart-table__options'>
									<li>Color: Yellow</li>
									<li>Material: Aluminium</li>
								</ul>
							</td>
							<td
								className='cart-table__column cart-table__column--price'
								data-title='Price'>
								$699.00
							</td>
							<td
								className='cart-table__column cart-table__column--quantity'
								data-title='Quantity'>
								<div className='input-number'>
									<input
										className='form-control input-number__input'
										type='number'
										min='1'
										defaultValue='2'
									/>
									<div className='input-number__add'></div>
									<div className='input-number__sub'></div>
								</div>
							</td>
							<td
								className='cart-table__column cart-table__column--total'
								data-title='Total'>
								$1,398.00
							</td>
							<td className='cart-table__column cart-table__column--remove'>
								<button type='button' className='btn btn-light btn-sm btn-svg-icon'>
									<svg width='12px' height='12px'>
										<use href='images/sprite.svg#cross-12'></use>
									</svg>
								</button>
							</td>
						</tr>
					</tbody>
				</table>
				<div className='cart__actions'>
					<form className='cart__coupon-form'>
						<label htmlFor='input-coupon-code' className='sr-only'>
							Password
						</label>
						<input
							type='text'
							className='form-control'
							id='input-coupon-code'
							placeholder='Coupon Code'
						/>
						<button type='submit' className='btn btn-primary'>
							Apply Coupon
						</button>
					</form>
					<div className='cart__buttons'>
						<Link to='/' className='btn btn-light'>
							Continue Shopping
						</Link>{' '}
						<span className='btn btn-primary cart__update-button'>Update Cart</span>
					</div>
				</div>
				<div className='row justify-content-end pt-5'>
					<div className='col-12 col-md-7 col-lg-6 col-xl-5'>
						<div className='card'>
							<div className='card-body'>
								<h3 className='card-title'>Cart Totals</h3>
								<table className='cart__totals'>
									<thead className='cart__totals-header'>
										<tr>
											<th>Subtotal</th>
											<td>$5,877.00</td>
										</tr>
									</thead>
									<tbody className='cart__totals-body'>
										<tr>
											<th>Shipping</th>
											<td>
												$25.00
												<div className='cart__calc-shipping'>
													<span>Calculate Shipping</span>
												</div>
											</td>
										</tr>
										<tr>
											<th>Tax</th>
											<td>$0.00</td>
										</tr>
									</tbody>
									<tfoot className='cart__totals-footer'>
										<tr>
											<th>Total</th>
											<td>$5,902.00</td>
										</tr>
									</tfoot>
								</table>
								<Link
									className='btn btn-primary btn-xl btn-block cart__checkout-button'
									to='/checkout'>
									Proceed to checkout
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Cart;
