import React from 'react';
import { Link } from 'react-router-dom';

const SingleItem = ({ img, title, reviews, price, isSale, isHot }) => {
	return (
		<div className='product-card'>
			<button className='product-card__quickview' type='button'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					fill='none'
					viewBox='0 0 24 24'
					strokeWidth={1.5}
					stroke='currentColor'
					style={{
						width: '16px',
					}}>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z'
					/>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
					/>
				</svg>

				<span className='fake-svg-icon'></span>
			</button>
			{isSale && (
				<div className='product-card__badges-list'>
					<div className='product-card__badge product-card__badge--sale'>
						Sale
					</div>
				</div>
			)}
			{isHot && (
				<div className='product-card__badges-list'>
					<div className='product-card__badge product-card__badge--hot'>
						Hot
					</div>
				</div>
			)}

			<div className='product-card__image'>
				<Link to='/product/asf54858'>
					<img src={img} alt='' />
				</Link>
			</div>
			<div className='product-card__info'>
				<div className='product-card__name'>
					<Link to='/product/asf54858'>{title}</Link>
				</div>
				<div className='product-card__rating'>
					<div className='rating'>
						<div className='rating__body'>
							<svg
								className='rating__star rating__star--active'
								width='13px'
								height='12px'>
								<g className='rating__fill'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth={1.5}
										stroke='currentColor'
										className='w-6 h-6'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z'
										/>
									</svg>
								</g>
								<g className='rating__stroke'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 24 24'
										fill='currentColor'
										className='w-6 h-6'>
										<path
											fillRule='evenodd'
											d='M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z'
											clipRule='evenodd'
										/>
									</svg>
								</g>
							</svg>
							<div className='rating__star rating__star--only-edge rating__star--active'>
								<div className='rating__fill'>
									<div className='fake-svg-icon'></div>
								</div>
								<div className='rating__stroke'>
									<div className='fake-svg-icon'></div>
								</div>
							</div>
							<svg
								className='rating__star rating__star--active'
								width='13px'
								height='12px'>
								<g className='rating__fill'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth={1.5}
										stroke='currentColor'
										className='w-6 h-6'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z'
										/>
									</svg>
								</g>
								<g className='rating__stroke'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 24 24'
										fill='currentColor'
										className='w-6 h-6'>
										<path
											fillRule='evenodd'
											d='M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z'
											clipRule='evenodd'
										/>
									</svg>
								</g>
							</svg>
							<div className='rating__star rating__star--only-edge rating__star--active'>
								<div className='rating__fill'>
									<div className='fake-svg-icon'></div>
								</div>
								<div className='rating__stroke'>
									<div className='fake-svg-icon'></div>
								</div>
							</div>
							<svg
								className='rating__star rating__star--active'
								width='13px'
								height='12px'>
								<g className='rating__fill'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth={1.5}
										stroke='currentColor'
										className='w-6 h-6'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z'
										/>
									</svg>
								</g>
								<g className='rating__stroke'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 24 24'
										fill='currentColor'
										className='w-6 h-6'>
										<path
											fillRule='evenodd'
											d='M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z'
											clipRule='evenodd'
										/>
									</svg>
								</g>
							</svg>
							<div className='rating__star rating__star--only-edge rating__star--active'>
								<div className='rating__fill'>
									<div className='fake-svg-icon'></div>
								</div>
								<div className='rating__stroke'>
									<div className='fake-svg-icon'></div>
								</div>
							</div>
							<svg
								className='rating__star rating__star--active'
								width='13px'
								height='12px'>
								<g className='rating__fill'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth={1.5}
										stroke='currentColor'
										className='w-6 h-6'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z'
										/>
									</svg>
								</g>
								<g className='rating__stroke'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 24 24'
										fill='currentColor'
										className='w-6 h-6'>
										<path
											fillRule='evenodd'
											d='M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z'
											clipRule='evenodd'
										/>
									</svg>
								</g>
							</svg>
							<div className='rating__star rating__star--only-edge rating__star--active'>
								<div className='rating__fill'>
									<div className='fake-svg-icon'></div>
								</div>
								<div className='rating__stroke'>
									<div className='fake-svg-icon'></div>
								</div>
							</div>
							<svg className='rating__star' width='13px' height='12px'>
								<g className='rating__fill'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth={1.5}
										stroke='currentColor'
										className='w-6 h-6'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z'
										/>
									</svg>
								</g>
								<g className='rating__stroke'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 24 24'
										fill='currentColor'
										className='w-6 h-6'>
										<path
											fillRule='evenodd'
											d='M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z'
											clipRule='evenodd'
										/>
									</svg>
								</g>
							</svg>
							<div className='rating__star rating__star--only-edge'>
								<div className='rating__fill'>
									<div className='fake-svg-icon'></div>
								</div>
								<div className='rating__stroke'>
									<div className='fake-svg-icon'></div>
								</div>
							</div>
						</div>
					</div>
					<div className='product-card__rating-legend'>{reviews} Reviews</div>
				</div>
			</div>
			<div className='product-card__actions'>
				<div className='product-card__availability'>
					Availability: <span className='text-success'>In Stock</span>
				</div>
				<div className='product-card__prices'>$149.00</div>
				<div className='product-card__buttons'>
					<button
						className='btn btn-primary product-card__addtocart'
						type='button'>
						Add To Cart
					</button>{' '}
					<button
						className='btn btn-secondary product-card__addtocart product-card__addtocart--list'
						type='button'>
						Add To Cart
					</button>{' '}
					<button
						className='btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist'
						type='button'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 24 24'
							strokeWidth={1.5}
							stroke='currentColor'
							className='w-6 h-6'>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z'
							/>
						</svg>
						<span className='fake-svg-icon fake-svg-icon--wishlist-16'></span>
					</button>
					<button
						className='btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare'
						type='button'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 24 24'
							strokeWidth={1.5}
							stroke='currentColor'
							style={{
								width: '16px',
							}}>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z'
							/>
						</svg>
						<span className='fake-svg-icon fake-svg-icon--compare-16'></span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default SingleItem;
