import { Route, Routes } from 'react-router-dom';
import './App.css';
import BlogDetails from './Components/Pages/BlogDetails';
import Blogs from './Components/Pages/Blogs';
import Cart from './Components/Pages/Cart';
import Checkout from './Components/Pages/Checkout';
import Contact from './Components/Pages/Contact';
import Home from './Components/Pages/Home';
import NotFound from './Components/Pages/NotFound';
import ProductDetails from './Components/Pages/ProductDetails';
import Store from './Components/Pages/Store';
import Wishlist from './Components/Pages/Wishlist';

function App() {
	return (
		<>
			<Routes>
				<Route path='*' element={<NotFound />} />
				<Route path='/' element={<Home />} />
				<Route path='/product/:id' element={<ProductDetails />} />
				<Route path='/wishlist' element={<Wishlist />} />
				<Route path='/cart' element={<Cart />} />
				<Route path='/checkout' element={<Checkout />} />
				<Route path='/shop' element={<Store />} />
				<Route path='/blogs' element={<Blogs />} />
				<Route path='/blogs/:id' element={<BlogDetails />} />
				<Route path='/contact' element={<Contact />} />
			</Routes>
		</>
	);
}

export default App;
