import React from 'react';

const MobileMenuSubItemL2 = ({children}) => {
	return (
		<div className='mobile-links__item-sub-links' data-collapse-content>
            <ul className='mobile-links mobile-links--level--2'>
                {children}
            </ul>
		</div>
	);
};

export default MobileMenuSubItemL2;
