import React from 'react';
import { Link } from 'react-router-dom';

const Breadcumb = () => {
	return (
		<div className='page-header'>
			<div className='page-header__container container'>
				<div className='page-header__breadcrumb'>
					<nav aria-label='breadcrumb'>
						<ol className='breadcrumb'>
							<li className='breadcrumb-item'>
								<a href='index.html'>Home</a>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									viewBox='0 0 24 24'
									strokeWidth={1.5}
									stroke='currentColor'
                                    style={{
                                        width:'16px'
                                    }}>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										d='M8.25 4.5l7.5 7.5-7.5 7.5'
									/>
								</svg>
							</li>
							<li className='breadcrumb-item'>
								<Link to='/'>Product</Link>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									viewBox='0 0 24 24'
									strokeWidth={1.5}
									stroke='currentColor'
                                    style={{
                                        width:'16px'
                                    }}>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										d='M8.25 4.5l7.5 7.5-7.5 7.5'
									/>
								</svg>
							</li>
							<li className='breadcrumb-item active' aria-current='page'>
								Brandix Screwdriver SCREW1500ACC
							</li>
						</ol>
					</nav>
				</div>
			</div>
		</div>
	);
};

export default Breadcumb;
