import React from 'react';

const SubMenu = ({ children }) => {
	return (
		<div className='menu__submenu'>
			<ul className='menu menu--layout--classic'>{children}</ul>
		</div>
	);
};

export default SubMenu;
