import React from 'react';
import Footer from '../Footer/Footer';
import Navigation from '../Navbar/Navigation';

const NotFound = () => {
	return (
		<>
			<Navigation />
			<div className='container my-5'>
				<div className='text-center'>
					<img src='/img/404.svg' className='w-50' alt='Page Not Found' />
					<h3 className='mt-4'>Page Not Found</h3>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default NotFound;
