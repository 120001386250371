import React from 'react';
import BlogSidebar from '../Blog/BlogSidebar';
import BlogItem from '../Common/BlogItem';
import Breadcumb from '../Common/Breadcumb';
import Footer from '../Footer/Footer';
import Navigation from '../Navbar/Navigation';

const Blogs = () => {
	return (
		<>
			<Navigation />
			<Breadcumb />
			<div className='container mb-4'>
				<div className='row'>
					<div className='col-12 col-lg-4'>
						<BlogSidebar />
					</div>
					<div className='col-12 col-lg-8'>
						<div className='block'>
							<div className='posts-view'>
								<div className='posts-view__list posts-list posts-list--layout--grid2'>
									<div className='posts-list__body'>
										<div className='posts-list__item'>
											<BlogItem />
										</div>
										<div className='posts-list__item'>
											<BlogItem />
										</div>
										<div className='posts-list__item'>
											<BlogItem />
										</div>
										<div className='posts-list__item'>
											<BlogItem />
										</div>
										<div className='posts-list__item'>
											<BlogItem />
										</div>
										<div className='posts-list__item'>
											<BlogItem />
										</div>
										<div className='posts-list__item'>
											<BlogItem />
										</div>
										<div className='posts-list__item'>
											<BlogItem />
										</div>
									</div>
								</div>
								<div className='posts-view__pagination'>
									<ul className='pagination justify-content-center'>
										<li className='page-item disabled'>
											<span
												className='page-link page-link--with-arrow'
												aria-label='Previous'>
												<svg
													className='page-link__arrow page-link__arrow--left'
													aria-hidden='true'
													width='8px'
													height='13px'>
													<use href='images/sprite.svg#arrow-rounded-left-8x13'></use>
												</svg>
											</span>
										</li>
										<li className='page-item'>
											<span className='page-link'>1</span>
										</li>
										<li className='page-item active'>
											<span className='page-link'>
												2 <span className='sr-only'>(current)</span>
											</span>
										</li>
										<li className='page-item'>
											<span className='page-link'>3</span>
										</li>
										<li className='page-item'>
											<span
												className='page-link page-link--with-arrow'
												aria-label='Next'>
												<svg
													className='page-link__arrow page-link__arrow--right'
													aria-hidden='true'
													width='8px'
													height='13px'>
													<use href='images/sprite.svg#arrow-rounded-right-8x13'></use>
												</svg>
											</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Blogs;
