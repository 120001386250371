import React from 'react';
import { Link } from 'react-router-dom';
import Breadcumb from '../Common/Breadcumb';
import Footer from '../Footer/Footer';
import Navigation from '../Navbar/Navigation';

const Wishlist = () => {
	return (
        <>
            <Navigation />
            <Breadcumb />
			<div class='container mb-5'>
				<table class='wishlist'>
					<thead class='wishlist__head'>
						<tr class='wishlist__row'>
							<th class='wishlist__column wishlist__column--image'>Image</th>
							<th class='wishlist__column wishlist__column--product'>
								Product
							</th>
							<th class='wishlist__column wishlist__column--stock'>
								Stock Status
							</th>
							<th class='wishlist__column wishlist__column--price'>Price</th>
							<th class='wishlist__column wishlist__column--tocart'></th>
							<th class='wishlist__column wishlist__column--remove'></th>
						</tr>
					</thead>
					<tbody class='wishlist__body'>
						<tr class='wishlist__row'>
							<td class='wishlist__column wishlist__column--image'>
								<Link to='/product/sample_product_0012'>
									<img
										src='https://static-01.daraz.com.bd/p/0b2cfed1f6048d9f98a5772d882fd99f.jpg'
										alt=''
									/>
								</Link>
							</td>
							<td class='wishlist__column wishlist__column--product'>
								<a href='#' class='wishlist__product-name'>
									Brandix Router Power Tool 2017ERXPK
								</a>
								<div class='wishlist__product-rating'>
									<div class='rating'>
										<div class='rating__body'>
											<svg
												class='rating__star rating__star--active'
												width='13px'
												height='12px'>
												<g class='rating__fill'>
													<use href='images/sprite.svg#star-normal'></use>
												</g>
												<g class='rating__stroke'>
													<use href='images/sprite.svg#star-normal-stroke'></use>
												</g>
											</svg>
											<div class='rating__star rating__star--only-edge rating__star--active'>
												<div class='rating__fill'>
													<div class='fake-svg-icon'></div>
												</div>
												<div class='rating__stroke'>
													<div class='fake-svg-icon'></div>
												</div>
											</div>
											<svg
												class='rating__star rating__star--active'
												width='13px'
												height='12px'>
												<g class='rating__fill'>
													<use href='images/sprite.svg#star-normal'></use>
												</g>
												<g class='rating__stroke'>
													<use href='images/sprite.svg#star-normal-stroke'></use>
												</g>
											</svg>
											<div class='rating__star rating__star--only-edge rating__star--active'>
												<div class='rating__fill'>
													<div class='fake-svg-icon'></div>
												</div>
												<div class='rating__stroke'>
													<div class='fake-svg-icon'></div>
												</div>
											</div>
											<svg
												class='rating__star rating__star--active'
												width='13px'
												height='12px'>
												<g class='rating__fill'>
													<use href='images/sprite.svg#star-normal'></use>
												</g>
												<g class='rating__stroke'>
													<use href='images/sprite.svg#star-normal-stroke'></use>
												</g>
											</svg>
											<div class='rating__star rating__star--only-edge rating__star--active'>
												<div class='rating__fill'>
													<div class='fake-svg-icon'></div>
												</div>
												<div class='rating__stroke'>
													<div class='fake-svg-icon'></div>
												</div>
											</div>
											<svg
												class='rating__star rating__star--active'
												width='13px'
												height='12px'>
												<g class='rating__fill'>
													<use href='images/sprite.svg#star-normal'></use>
												</g>
												<g class='rating__stroke'>
													<use href='images/sprite.svg#star-normal-stroke'></use>
												</g>
											</svg>
											<div class='rating__star rating__star--only-edge rating__star--active'>
												<div class='rating__fill'>
													<div class='fake-svg-icon'></div>
												</div>
												<div class='rating__stroke'>
													<div class='fake-svg-icon'></div>
												</div>
											</div>
											<svg
												class='rating__star rating__star--active'
												width='13px'
												height='12px'>
												<g class='rating__fill'>
													<use href='images/sprite.svg#star-normal'></use>
												</g>
												<g class='rating__stroke'>
													<use href='images/sprite.svg#star-normal-stroke'></use>
												</g>
											</svg>
											<div class='rating__star rating__star--only-edge rating__star--active'>
												<div class='rating__fill'>
													<div class='fake-svg-icon'></div>
												</div>
												<div class='rating__stroke'>
													<div class='fake-svg-icon'></div>
												</div>
											</div>
										</div>
									</div>
									<div class='wishlist__product-rating-legend'>11 Reviews</div>
								</div>
							</td>
							<td class='wishlist__column wishlist__column--stock'>
								<div class='badge bg-success'>In Stock</div>
							</td>
							<td class='wishlist__column wishlist__column--price'>
								$1,210.00
							</td>
							<td class='wishlist__column wishlist__column--tocart'>
								<button type='button' class='btn btn-primary btn-sm'>
									Add To Cart
								</button>
							</td>
							<td class='wishlist__column wishlist__column--remove'>
								<button type='button' class='btn btn-light btn-sm btn-svg-icon'>
									<svg width='12px' height='12px'>
										<use href='images/sprite.svg#cross-12'></use>
									</svg>
								</button>
							</td>
						</tr>
					</tbody>
				</table>
            </div>
            <Footer />
		</>
	);
};

export default Wishlist;
