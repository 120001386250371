import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DropCart from '../Common/DropCart';
import Navlink from './Desktop/Navlink';
import NavMenu from './Desktop/NavMenu';
import NavPanel from './Desktop/NavPanel';
import SubMenu from './Desktop/SubMenu';
import SubMenuLink from './Desktop/SubMenuLink';
import Topbar from './Desktop/Topbar';
import MobileMenu from './MobileMenu';

const Navigation = () => {
	const [mbhOpen, setMbhOpen] = useState(false);
	const [mbmOpen, setMbmOpen] = useState(false);

	const location = useLocation();
	const isHome = location.pathname.replace('/', '') === '';
	const [isDropCartOpen, setIsDropCartOpen] = useState(false);
	const [isCatOpen, setIsCatOpen] = useState(false);
	return (
		<>
			<MobileMenu open={mbmOpen} setOpen={setMbmOpen} />
			<header className='site__header d-lg-none'>
				<div className='mobile-header mobile-header--sticky mobile-header--stuck'>
					<div className='mobile-header__panel'>
						<div className='container'>
							<div className='mobile-header__body'>
								<button
									className='mobile-header__menu-button'
									onClick={() => setMbmOpen(!mbmOpen)}>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth={1.5}
										stroke='currentColor'
										style={{
											width: '20px',
										}}>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12'
										/>
									</svg>
								</button>{' '}
								<Link className='mobile-header__logo' to='/'>
									<img
										src='/img/logo-white.svg'
										style={{ width: '120px' }}
										alt=''
									/>
								</Link>
								<div
									className={`mobile-header__search ${
										mbhOpen ? 'mobile-header__search--opened' : ''
									}`}>
									<form className='mobile-header__search-form' action='#'>
										<input
											className='mobile-header__search-input'
											name='search'
											placeholder='Search over 10,000 products'
											aria-label='Site search'
											type='text'
											autoComplete='off'
										/>{' '}
										<button
											className='mobile-header__search-button mobile-header__search-button--submit'
											type='submit'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												fill='none'
												viewBox='0 0 24 24'
												strokeWidth={1.5}
												stroke='currentColor'
												style={{
													width: '18px',
												}}>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z'
												/>
											</svg>
										</button>{' '}
										<button
											className='mobile-header__search-button mobile-header__search-button--close'
											onClick={() => setMbhOpen(false)}
											type='button'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												fill='none'
												viewBox='0 0 24 24'
												strokeWidth={1.5}
												stroke='currentColor'
												style={{
													width: '18px',
												}}>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													d='M6 18L18 6M6 6l12 12'
												/>
											</svg>
										</button>
										<div className='mobile-header__search-body'></div>
									</form>
								</div>
								<div className='mobile-header__indicators'>
									<div className='indicator indicator--mobile-search indicator--mobile d-sm-none'>
										<button
											className='indicator__button'
											onClick={() => setMbhOpen(!mbhOpen)}>
											<span className='indicator__area'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													fill='none'
													viewBox='0 0 24 24'
													strokeWidth={1.5}
													stroke='currentColor'
													style={{
														width: '20px',
													}}
													className='w-6 h-6'>
													<path
														strokeLinecap='round'
														strokeLinejoin='round'
														d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z'
													/>
												</svg>
											</span>
										</button>
									</div>
									<div className='indicator indicator--mobile d-sm-flex d-none'>
										<Link to='/wishlist' className='indicator__button'>
											<span className='indicator__area'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													fill='none'
													viewBox='0 0 24 24'
													strokeWidth={1.5}
													stroke='currentColor'
													style={{
														width: '20px',
													}}>
													<path
														strokeLinecap='round'
														strokeLinejoin='round'
														d='M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z'
													/>
												</svg>{' '}
												<span className='indicator__value'>0</span>
											</span>
										</Link>
									</div>
									<div className='indicator indicator--mobile'>
										<Link to='/cart' className='indicator__button'>
											<span className='indicator__area'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													fill='none'
													viewBox='0 0 24 24'
													strokeWidth={1.5}
													stroke='currentColor'
													style={{
														width: '20px',
													}}
													className='w-6 h-6'>
													<path
														strokeLinecap='round'
														strokeLinejoin='round'
														d='M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z'
													/>
												</svg>{' '}
												<span className='indicator__value'>3</span>
											</span>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
			{/* <!-- mobile site__header / end --> */}
			{/* <!-- desktop site__header --> */}
			<header className='site__header d-lg-block d-none'>
				<div className='site-header'>
					{/* <!-- .top bar --> */}
					<Topbar />
					{/* top bar end */}
					<div className='site-header__middle container'>
						<div className='site-header__logo'>
							<Link to='/'>
								<img src='/img/logo.svg' style={{ width: '196px' }} alt='' />
							</Link>
						</div>
						<div className='site-header__search'>
							<div className='search'>
								<form className='search__form' action='#'>
									<input
										className='search__input'
										name='search'
										placeholder='Search over 10,000 products'
										aria-label='Site search'
										type='text'
										autoComplete='off'
									/>{' '}
									<button className='search__button' type='submit'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											fill='none'
											viewBox='0 0 24 24'
											strokeWidth={1.5}
											stroke='currentColor'
											style={{
												width: '20px',
											}}
											className='w-6 h-6'>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z'
											/>
										</svg>
									</button>
									<div className='search__border'></div>
								</form>
							</div>
						</div>
						<div className='site-header__phone'>
							<div className='site-header__phone-title'>Customer Service</div>
							<div className='site-header__phone-number'>
								(880) 163-1234-086
							</div>
						</div>
					</div>
					<div className='site-header__nav-panel'>
						<div className='nav-panel'>
							<div className='nav-panel__container container'>
								<div className='nav-panel__row'>
									<div className='nav-panel__departments'>
										{/* <!-- .departments --> */}
										<div
											className={`${
												isHome || isCatOpen
													? 'departments departments--opened'
													: 'departments'
											}`}>
											<div className='departments__body'>
												<div className='departments__links-wrapper'>
													<ul className='departments__links'>
														<li className='departments__item'>
															<a href='/'>
																Electronics{' '}
																<svg
																	className='departments__link-arrow'
																	width='6px'
																	height='9px'>
																	<use href='images/sprite.svg#arrow-rounded-right-6x9'></use>
																</svg>
															</a>
															<div className='departments__megamenu departments__megamenu--xl'>
																{/* <!-- .megamenu --> */}
																<div
																	className='megamenu megamenu--departments'
																	// style="background-image: url('images/megamenu/megamenu-1.jpg');"
																>
																	<div className='row'>
																		<div className='col-3'>
																			<ul className='megamenu__links megamenu__links--level--0'>
																				<li className='megamenu__item megamenu__item--with-submenu'>
																					<a href='/'>Accessories & Supplies</a>
																					<ul className='megamenu__links megamenu__links--level--1'>
																						<li className='megamenu__item'>
																							<a href='/'>
																								Camera & Photo Accessories
																							</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>
																								Cell Phone Accessories
																							</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>
																								GPS System Accessories
																							</a>
																						</li>
																					</ul>
																				</li>
																				<li className='megamenu__item'>
																					<a href='/'>Workbenches</a>
																				</li>
																				<li className='megamenu__item'>
																					<a href='/'>Presses</a>
																				</li>
																				<li className='megamenu__item'>
																					<a href='/'>Spray Guns</a>
																				</li>
																				<li className='megamenu__item'>
																					<a href='/'>Riveters</a>
																				</li>
																			</ul>
																		</div>
																		<div className='col-3'>
																			<ul className='megamenu__links megamenu__links--level--0'>
																				<li className='megamenu__item megamenu__item--with-submenu'>
																					<a href='/'>Camera & Photo</a>
																					<ul className='megamenu__links megamenu__links--level--1'>
																						<li className='megamenu__item'>
																							<a href='/'>Accessories</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Bags & Cases</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>
																								Binoculars & Scopes
																							</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Digital Cameras</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Lighting & Studio</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Simulated Cameras</a>
																						</li>
																					</ul>
																				</li>
																				<li className='megamenu__item megamenu__item--with-submenu'>
																					<a href='/'>Garden Equipment</a>
																					<ul className='megamenu__links megamenu__links--level--1'>
																						<li className='megamenu__item'>
																							<a href='/'>Motor Pumps</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Chainsaws</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Electric Saws</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Brush Cutters</a>
																						</li>
																					</ul>
																				</li>
																			</ul>
																		</div>
																		<div className='col-3'>
																			<ul className='megamenu__links megamenu__links--level--0'>
																				<li className='megamenu__item megamenu__item--with-submenu'>
																					<a href='/'>Machine Tools</a>
																					<ul className='megamenu__links megamenu__links--level--1'>
																						<li className='megamenu__item'>
																							<a href='/'>Thread Cutting</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Chip Blowers</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>
																								Sharpening Machines
																							</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Pipe Cutters</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Slotting machines</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Lathes</a>
																						</li>
																					</ul>
																				</li>
																			</ul>
																		</div>
																		<div className='col-3'>
																			<ul className='megamenu__links megamenu__links--level--0'>
																				<li className='megamenu__item megamenu__item--with-submenu'>
																					<a href='/'>Instruments</a>
																					<ul className='megamenu__links megamenu__links--level--1'>
																						<li className='megamenu__item'>
																							<a href='/'>Welding Equipment</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Power Tools</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Hand Tools</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Measuring Tool</a>
																						</li>
																					</ul>
																				</li>
																			</ul>
																		</div>
																	</div>
																</div>
																{/* <!-- .megamenu / end --> */}
															</div>
														</li>
														<li className='departments__item'>
															<a href='/'>
																Hand Tools{' '}
																<svg
																	className='departments__link-arrow'
																	width='6px'
																	height='9px'>
																	<use href='images/sprite.svg#arrow-rounded-right-6x9'></use>
																</svg>
															</a>
															<div className='departments__megamenu departments__megamenu--lg'>
																{/* <!-- .megamenu --> */}
																<div
																	className='megamenu megamenu--departments'
																	// style="background-image: url('images/megamenu/megamenu-2.jpg');"
																>
																	<div className='row'>
																		<div className='col-4'>
																			<ul className='megamenu__links megamenu__links--level--0'>
																				<li className='megamenu__item megamenu__item--with-submenu'>
																					<a href='/'>Hand Tools</a>
																					<ul className='megamenu__links megamenu__links--level--1'>
																						<li className='megamenu__item'>
																							<a href='/'>Screwdrivers</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Handsaws</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Knives</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Axes</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Multitools</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Paint Tools</a>
																						</li>
																					</ul>
																				</li>
																				<li className='megamenu__item megamenu__item--with-submenu'>
																					<a href='/'>Garden Equipment</a>
																					<ul className='megamenu__links megamenu__links--level--1'>
																						<li className='megamenu__item'>
																							<a href='/'>Motor Pumps</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Chainsaws</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Electric Saws</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Brush Cutters</a>
																						</li>
																					</ul>
																				</li>
																			</ul>
																		</div>
																		<div className='col-4'>
																			<ul className='megamenu__links megamenu__links--level--0'>
																				<li className='megamenu__item megamenu__item--with-submenu'>
																					<a href='/'>Machine Tools</a>
																					<ul className='megamenu__links megamenu__links--level--1'>
																						<li className='megamenu__item'>
																							<a href='/'>Thread Cutting</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Chip Blowers</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>
																								Sharpening Machines
																							</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Pipe Cutters</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Slotting machines</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Lathes</a>
																						</li>
																					</ul>
																				</li>
																			</ul>
																		</div>
																		<div className='col-4'>
																			<ul className='megamenu__links megamenu__links--level--0'>
																				<li className='megamenu__item megamenu__item--with-submenu'>
																					<a href='/'>Instruments</a>
																					<ul className='megamenu__links megamenu__links--level--1'>
																						<li className='megamenu__item'>
																							<a href='/'>Welding Equipment</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Power Tools</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Hand Tools</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Measuring Tool</a>
																						</li>
																					</ul>
																				</li>
																			</ul>
																		</div>
																	</div>
																</div>
																{/* <!-- .megamenu / end --> */}
															</div>
														</li>
														<li className='departments__item'>
															<a href='/'>
																Machine Tools{' '}
																<svg
																	className='departments__link-arrow'
																	width='6px'
																	height='9px'>
																	<use href='images/sprite.svg#arrow-rounded-right-6x9'></use>
																</svg>
															</a>
															<div className='departments__megamenu departments__megamenu--nl'>
																{/* <!-- .megamenu --> */}
																<div
																	className='megamenu megamenu--departments'
																	// style="background-image: url('images/megamenu/megamenu-3.jpg');"
																>
																	<div className='row'>
																		<div className='col-6'>
																			<ul className='megamenu__links megamenu__links--level--0'>
																				<li className='megamenu__item megamenu__item--with-submenu'>
																					<a href='/'>Hand Tools</a>
																					<ul className='megamenu__links megamenu__links--level--1'>
																						<li className='megamenu__item'>
																							<a href='/'>Screwdrivers</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Handsaws</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Knives</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Axes</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Multitools</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Paint Tools</a>
																						</li>
																					</ul>
																				</li>
																				<li className='megamenu__item megamenu__item--with-submenu'>
																					<a href='/'>Garden Equipment</a>
																					<ul className='megamenu__links megamenu__links--level--1'>
																						<li className='megamenu__item'>
																							<a href='/'>Motor Pumps</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Chainsaws</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Electric Saws</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Brush Cutters</a>
																						</li>
																					</ul>
																				</li>
																			</ul>
																		</div>
																		<div className='col-6'>
																			<ul className='megamenu__links megamenu__links--level--0'>
																				<li className='megamenu__item megamenu__item--with-submenu'>
																					<a href='/'>Instruments</a>
																					<ul className='megamenu__links megamenu__links--level--1'>
																						<li className='megamenu__item'>
																							<a href='/'>Welding Equipment</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Power Tools</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Hand Tools</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Measuring Tool</a>
																						</li>
																					</ul>
																				</li>
																			</ul>
																		</div>
																	</div>
																</div>
																{/* <!-- .megamenu / end --> */}
															</div>
														</li>
														<li className='departments__item'>
															<a href='/'>
																Building Supplies{' '}
																<svg
																	className='departments__link-arrow'
																	width='6px'
																	height='9px'>
																	<use href='images/sprite.svg#arrow-rounded-right-6x9'></use>
																</svg>
															</a>
															<div className='departments__megamenu departments__megamenu--sm'>
																{/* <!-- .megamenu --> */}
																<div className='megamenu megamenu--departments'>
																	<div className='row'>
																		<div className='col-12'>
																			<ul className='megamenu__links megamenu__links--level--0'>
																				<li className='megamenu__item megamenu__item--with-submenu'>
																					<a href='/'>Hand Tools</a>
																					<ul className='megamenu__links megamenu__links--level--1'>
																						<li className='megamenu__item'>
																							<a href='/'>Screwdrivers</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Handsaws</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Knives</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Axes</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Multitools</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Paint Tools</a>
																						</li>
																					</ul>
																				</li>
																				<li className='megamenu__item megamenu__item--with-submenu'>
																					<a href='/'>Garden Equipment</a>
																					<ul className='megamenu__links megamenu__links--level--1'>
																						<li className='megamenu__item'>
																							<a href='/'>Motor Pumps</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Chainsaws</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Electric Saws</a>
																						</li>
																						<li className='megamenu__item'>
																							<a href='/'>Brush Cutters</a>
																						</li>
																					</ul>
																				</li>
																			</ul>
																		</div>
																	</div>
																</div>
																{/* <!-- .megamenu / end --> */}
															</div>
														</li>
														<li className='departments__item departments__item--menu'>
															<a href='/'>
																Electrical{' '}
																<svg
																	className='departments__link-arrow'
																	width='6px'
																	height='9px'>
																	<use href='images/sprite.svg#arrow-rounded-right-6x9'></use>
																</svg>
															</a>
															<div className='departments__menu'>
																{/* <!-- .menu --> */}
																<ul className='menu menu--layout--classic'>
																	<li>
																		<a href='/'>
																			Soldering Equipment{' '}
																			<svg
																				className='menu__arrow'
																				width='6px'
																				height='9px'>
																				<use href='images/sprite.svg#arrow-rounded-right-6x9'></use>
																			</svg>
																		</a>
																		<div className='menu__submenu'>
																			{/* <!-- .menu --> */}
																			<ul className='menu menu--layout--classic'>
																				<li>
																					<a href='/'>Soldering Station</a>
																				</li>
																				<li>
																					<a href='/'>Soldering Dryers</a>
																				</li>
																				<li>
																					<a href='/'>Gas Soldering Iron</a>
																				</li>
																				<li>
																					<a href='/'>
																						Electric Soldering Iron
																					</a>
																				</li>
																			</ul>
																			{/* <!-- .menu / end --> */}
																		</div>
																	</li>
																	<li>
																		<a href='/'>Light Bulbs</a>
																	</li>
																	<li>
																		<a href='/'>Batteries</a>
																	</li>
																	<li>
																		<a href='/'>Light Fixtures</a>
																	</li>
																	<li>
																		<a href='/'>Warm Floor</a>
																	</li>
																	<li>
																		<a href='/'>Generators</a>
																	</li>
																	<li>
																		<a href='/'>UPS</a>
																	</li>
																</ul>
																{/* <!-- .menu / end --> */}
															</div>
														</li>
														<li className='departments__item'>
															<a href='/'>Power Machinery</a>
														</li>
														<li className='departments__item'>
															<a href='/'>Measurement</a>
														</li>
														<li className='departments__item'>
															<a href='/'>Clothes & PPE</a>
														</li>
														<li className='departments__item'>
															<a href='/'>Plumbing</a>
														</li>
														<li className='departments__item'>
															<a href='/'>Storage & Organization</a>
														</li>
														<li className='departments__item'>
															<a href='/'>Welding & Soldering</a>
														</li>
													</ul>
												</div>
											</div>
											<button
												className='departments__button'
												onClick={() => setIsCatOpen(!isCatOpen)}>
												<svg
													className='departments__button-icon'
													xmlns='http://www.w3.org/2000/svg'
													fill='none'
													viewBox='0 0 24 24'
													strokeWidth={1.5}
													stroke='currentColor'
													style={{
														width: '20px',
													}}>
													<path
														strokeLinecap='round'
														strokeLinejoin='round'
														d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12'
													/>
												</svg>{' '}
												Shop By Category{' '}
												<svg
													className='departments__button-arrow'
													xmlns='http://www.w3.org/2000/svg'
													fill='none'
													viewBox='0 0 24 24'
													strokeWidth={1.5}
													stroke='currentColor'
													style={{ width: '12px' }}>
													<path
														strokeLinecap='round'
														strokeLinejoin='round'
														d='M19.5 8.25l-7.5 7.5-7.5-7.5'
													/>
												</svg>
											</button>
										</div>
										{/* <!-- .departments / end --> */}
									</div>
									{/* <!-- .nav-links --> */}
									<NavPanel>
										<Navlink title='Home' link='/' />
										<SubMenuLink title='Shop' link='/shop'>
											<NavMenu>
												<li>
													<Link to='/shop'>Product</Link>
													<SubMenu>
														<li>
															<Link tp='/'>Category 1</Link>
														</li>
														<li>
															<Link tp='/'>Category 2</Link>
														</li>
														<li>
															<Link tp='/'>Category 3</Link>
														</li>
													</SubMenu>
												</li>
												<li>
													<Link to='/cart'>Cart</Link>
												</li>
												<li>
													<Link to='/checkout'>Checkout</Link>
												</li>
												<li>
													<Link to='/wishlist'>Wishlist</Link>
												</li>
												<li>
													<Link to='/compare'>Compare</Link>
												</li>
												<li>
													<Link to='/account'>My Account</Link>
												</li>
												<li>
													<Link to='/track'>Track Order</Link>
												</li>
											</NavMenu>
										</SubMenuLink>
										<li className='nav-links__item nav-links__item--with-submenu'>
											<Link to='/blogs'>
												<span>
													Blog{' '}
													<svg
														className='nav-links__arrow'
														width='9px'
														height='6px'>
														<use href='images/sprite.svg#arrow-rounded-down-9x6'></use>
													</svg>
												</span>
											</Link>
										</li>
										<li className='nav-links__item'>
											<Link to='/contact'>
												<span>Contact Us</span>
											</Link>
										</li>
									</NavPanel>
									{/* <!-- .nav-links / end --> */}
									<div className='nav-panel__indicators'>
										<div className='indicator'>
											<Link to='/wishlist' className='indicator__button'>
												<span className='indicator__area'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														fill='none'
														viewBox='0 0 24 24'
														strokeWidth={1.5}
														stroke='currentColor'
														style={{
															width: '20px',
														}}>
														<path
															strokeLinecap='round'
															strokeLinejoin='round'
															d='M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z'
														/>
													</svg>{' '}
													<span className='indicator__value'>0</span>
												</span>
											</Link>
										</div>
										<div
											className={`indicator indicator--trigger--click ${
												isDropCartOpen ? 'indicator--opened' : ''
											}`}>
											<span
												className='indicator__button'
												onClick={() => setIsDropCartOpen(!isDropCartOpen)}>
												<span className='indicator__area'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														fill='none'
														viewBox='0 0 24 24'
														strokeWidth={1.5}
														stroke='currentColor'
														style={{
															width: '20px',
														}}>
														<path
															strokeLinecap='round'
															strokeLinejoin='round'
															d='M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z'
														/>
													</svg>
													<span className='indicator__value'>3</span>
												</span>
											</span>
											<DropCart />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	);
};

export default Navigation;
