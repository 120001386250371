import React from 'react';
import { Link } from 'react-router-dom';
import DropCatProduct from './DropCatProduct';

const DropCart = () => {
	return (
		<div className='indicator__dropdown'>
			{/* <!-- .dropcart --> */}
			<div className='dropcart'>
				<div className='dropcart__products-list'>
					<DropCatProduct />
					<DropCatProduct />
					<DropCatProduct />
				</div>
				<div className='dropcart__totals'>
					<table>
						<tr>
							<th>Subtotal</th>
							<td>$5,877.00</td>
						</tr>
						<tr>
							<th>Shipping</th>
							<td>$25.00</td>
						</tr>
						<tr>
							<th>Tax</th>
							<td>$0.00</td>
						</tr>
						<tr>
							<th>Total</th>
							<td>$5,902.00</td>
						</tr>
					</table>
				</div>
				<div className='dropcart__buttons'>
					<Link className='btn btn-secondary' to='/cart'>
						View Cart
					</Link>{' '}
					<Link className='btn btn-primary' to='/checkout'>
						Checkout
					</Link>
				</div>
			</div>
			{/* <!-- .dropcart / end --> */}
		</div>
	);
};

export default DropCart;
