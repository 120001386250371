import React from 'react';
import MobileMenuContent from './Mobile/MobileMenuContent';
import MobileMenuLinksItem from './Mobile/MobileMenuLinksItem';
import MobileMenuSubItem from './Mobile/MobileMenuSubItem';
import MobileMenuSubItemL2 from './Mobile/MobileMenuSubItemL2';

const MobileMenu = ({open, setOpen}) => {
	return (
		<>
			<div className={`mobilemenu ${open ? 'mobilemenu--open' : ''}`}>
				<div className='mobilemenu__backdrop'></div>
				<div className='mobilemenu__body'>
					<div className='mobilemenu__header'>
						<div className='mobilemenu__title'>Menu</div>
						<button type='button' className='mobilemenu__close' onClick={() => setOpen(false)}>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth={1.5}
								stroke='currentColor'
								style={{
									width: '20px',
								}}>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='M6 18L18 6M6 6l12 12'
								/>
							</svg>
						</button>
					</div>
					<MobileMenuContent>
						<MobileMenuLinksItem title={'Home'} link='/' arrow={false} />
						<MobileMenuLinksItem title={'Categories'} link='/'>
							<MobileMenuSubItem>
								<MobileMenuLinksItem title='Power Tools' link='/'>
									<MobileMenuSubItemL2>
										<MobileMenuLinksItem
											title={'Engravers'}
											link='/'
											arrow={false}
										/>
										<MobileMenuLinksItem
											title={'Wrenches'}
											link='/'
											arrow={false}
										/>
									</MobileMenuSubItemL2>
								</MobileMenuLinksItem>
								<MobileMenuLinksItem title='Machine Tools' link='/'>
									<MobileMenuSubItemL2>
										<MobileMenuLinksItem
											title={'Thread Cutting'}
											link='/'
											arrow={false}
										/>
										<MobileMenuLinksItem
											title={'Chip Blowers'}
											link='/'
											arrow={false}
										/>
									</MobileMenuSubItemL2>
								</MobileMenuLinksItem>
							</MobileMenuSubItem>
						</MobileMenuLinksItem>
                        <MobileMenuLinksItem title='Shop' link='/'>
                            <MobileMenuSubItem>
                                <MobileMenuLinksItem title='Products' link='/' arrow={false} />
                                <MobileMenuLinksItem title='Cart' link='/' arrow={false} />
                                <MobileMenuLinksItem title='Checkout' link='/' arrow={false} />
                                <MobileMenuLinksItem title='Wishlist' link='/' arrow={false} />
                                <MobileMenuLinksItem title='Compare' link='/' arrow={false} />
                                <MobileMenuLinksItem title='My Account' link='/' arrow={false} />
                                <MobileMenuLinksItem title='Track Order' link='/' arrow={false} />
                            </MobileMenuSubItem>
						</MobileMenuLinksItem>
						<MobileMenuLinksItem title='Blog' link='/' arrow={false} />
                        <MobileMenuLinksItem title='Language' link='/'>
                            <MobileMenuSubItem>
                                <MobileMenuLinksItem title='English' link='/' arrow={false} />
                                <MobileMenuLinksItem title='Bangla' link='/' arrow={false} />
                            </MobileMenuSubItem>
                        </MobileMenuLinksItem>
					</MobileMenuContent>
				</div>
			</div>
		</>
	);
};

export default MobileMenu;
