import React from 'react';

const MobileMenuContent = ({ children }) => {
	return (
		<div className='mobilemenu__content'>
			<ul
				className='mobile-links mobile-links--level--0'
				data-collapse
				data-collapse-opened-class='mobile-links__item--open'>
				{children}
			</ul>
		</div>
	);
};

export default MobileMenuContent;
