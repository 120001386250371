import React from 'react';
import { Link } from 'react-router-dom';

const Navlink = ({ title, link }) => {
	return (
		<li className='nav-links__item nav-links__item--with-submenu'>
			<Link to={link}>
				<span>{title}</span>
			</Link>
		</li>
	);
};

export default Navlink;
