import React from 'react';
import { Link } from 'react-router-dom';

const LatestProductItem = () => {
	return (
		<div class='widget-products__item'>
			<div class='widget-products__image'>
				<a href='product.html'>
					<img
						src='https://static-01.daraz.com.bd/p/0b2cfed1f6048d9f98a5772d882fd99f.jpg'
						alt=''
					/>
				</a>
			</div>
			<div class='widget-products__info'>
				<div class='widget-products__name'>
					<Link to='/product/54154'>Electric Planer Brandix KL370090G 300 Watts</Link>
				</div>
				<div class='widget-products__prices'>$749.00</div>
			</div>
		</div>
	);
};

export default LatestProductItem;
