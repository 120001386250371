import React from 'react';
import Breadcumb from '../Common/Breadcumb';
import Footer from '../Footer/Footer';
import Navigation from '../Navbar/Navigation';

const Contact = () => {
	return (
		<>
			<Navigation />
			<Breadcumb />
			<div className='container mb-5'>
				<h5 className='text-center'> Page Coming Soon ... </h5>
			</div>
			<Footer />
		</>
	);
};

export default Contact;
