import React from 'react';

const NavPanel = ({ children }) => {
	return (
		<div className='nav-panel__nav-links nav-links'>
			<ul className='nav-links__list'>{children}</ul>
		</div>
	);
};

export default NavPanel;
