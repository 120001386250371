import React from 'react';
import FeaturedProduct from '../FeaturedProduct/FeaturedProduct';
import NewProduct from '../FeaturedProduct/NewProduct';
import Footer from '../Footer/Footer';
import Hero from '../Hero/Hero';
import Navigation from '../Navbar/Navigation';
import Promotion from '../Promotion/Promotion';

const Home = () => {
	return (
		<>
			<Navigation />
			<Hero />
			<FeaturedProduct />
            <Promotion />
            <NewProduct />
            <Footer />
		</>
	);
};

export default Home;
