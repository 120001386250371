import React from 'react';
import ReactOwlCarousel from 'react-owl-carousel';
import SingleItemCR from './SingleItemCR';

const FeaturedProduct = () => {
	const options = {
		responsive: {
			400: {
				items: 1,
			},
			600: {
				items: 2,
			},
			800: {
				items: 3,
			},
			1200: {
				items: 4,
			},
		},
	};
	return (
		<div className='block block-products-carousel' data-layout='grid-4'>
			<div className='container'>
				<div className='block-header'>
					<h3 className='block-header__title'>Featured Products</h3>
					<div className='block-header__divider'></div>
					<ul className='block-header__groups-list'>
						<li>
							<button
								type='button'
								className='block-header__group block-header__group--active'>
								All
							</button>
						</li>
						<li>
							<button type='button' className='block-header__group'>
								Man Fashion
							</button>
						</li>
						<li>
							<button type='button' className='block-header__group'>
								Women Fashion
							</button>
						</li>
						<li>
							<button type='button' className='block-header__group'>
								Electronics
							</button>
						</li>
					</ul>
					<div className='block-header__arrows-list'>
						<button
							className='block-header__arrow block-header__arrow--left'
							type='button'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth={1.5}
								stroke='currentColor'
								style={{
									width: '14px',
								}}>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='M15.75 19.5L8.25 12l7.5-7.5'
								/>
							</svg>
						</button>{' '}
						<button
							className='block-header__arrow block-header__arrow--right'
							type='button'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth={1.5}
								stroke='currentColor'
								style={{
									width: '14px',
								}}>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='M8.25 4.5l7.5 7.5-7.5 7.5'
								/>
							</svg>
						</button>
					</div>
				</div>
				<div className='block-products-carousel__slider'>
					<div className='block-products-carousel__preloader'></div>
					<ReactOwlCarousel items={4} margin={15} {...options}>
						<SingleItemCR
							title='Dummy Product 1'
							img='https://static-01.daraz.com.bd/p/0addc405b133e6559b7a17c99be907d9.jpg'
						/>
						<SingleItemCR
							title='Dummy Product 2'
							img='https://static-01.daraz.com.bd/p/123fff165d5f25a3eae038b90d22722d.jpg'
							isHot={true}
						/>
						<SingleItemCR
							isSale={true}
							title='Dummy Product 3'
							img='https://static-01.daraz.com.bd/p/0b2cfed1f6048d9f98a5772d882fd99f.jpg'
						/>
						<SingleItemCR
							title='Dummy Product 4'
							img='https://static-01.daraz.com.bd/p/0ef12d2214da07642c74b087e3d074db.jpg'
							isHot={true}
						/>
						<SingleItemCR
							title='Dummy Product 5'
							img='https://static-01.daraz.com.bd/p/cf664bf021d2b225d73dadf8fb726115.jpg'
							isSale={true}
						/>
					</ReactOwlCarousel>
				</div>
			</div>
		</div>
	);
};

export default FeaturedProduct;
