import React, { useEffect } from 'react';
import ReactOwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import Breadcumb from '../Common/Breadcumb';
import Footer from '../Footer/Footer';
import Navigation from '../Navbar/Navigation';
import ProductDetailsTab from '../ProductDetailsTab/ProductDetailsTab';

const ProductDetails = () => {
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, [])
	return (
		<div>
			<Navigation />
			<Breadcumb />
			<div className='container mb-4'>
				<div className='product product--layout--standard' data-layout='standard'>
					<div className='product__content'>
						{/* <!-- .product__gallery --> */}
						<div className='product__gallery'>
							<div className='product-gallery'>
								<div className='product-gallery__featured'>
									<ReactOwlCarousel items={1} className='owl-carousel' id='product-image'>
										<span>
											<img
												src='https://static-01.daraz.com.bd/p/23a8ec4bc8311befa66b0d95770da7a0.jpg'
												alt=''
											/>{' '}
										</span>
										<span>
											<img
												src='https://static-01.daraz.com.bd/p/64699c519cf80033d54b6d72f6a4885d.jpg'
												alt=''
											/>{' '}
										</span>
										<span>
											<img
												src='https://static-01.daraz.com.bd/p/d4bd18c4b9b955425ef7c0a3283f1aae.jpg'
												alt=''
											/>{' '}
										</span>
									</ReactOwlCarousel>
								</div>
								<div className='product-gallery__carousel'>
									<ReactOwlCarousel items={5} margin={15} className='owl-carousel' id='product-carousel'>
                                        <span
                                            onClick={() => this.refs.productRef.goTo(0)}
                                            className='product-gallery__carousel-item'>
											<img
												className='product-gallery__carousel-image'
												src='https://static-01.daraz.com.bd/p/23a8ec4bc8311befa66b0d95770da7a0.jpg'
												alt=''
											/>
										</span>
										<span onClick={() => this.refs.productRef.goTo(1)}  className='product-gallery__carousel-item'>
											<img
												className='product-gallery__carousel-image'
												src='https://static-01.daraz.com.bd/p/64699c519cf80033d54b6d72f6a4885d.jpg'
												alt=''
											/>{' '}
										</span>
										<span to='/' className='product-gallery__carousel-item'>
											<img
												className='product-gallery__carousel-image'
												src='https://static-01.daraz.com.bd/p/d4bd18c4b9b955425ef7c0a3283f1aae.jpg'
												alt=''
											/>{' '}
										</span>
									</ReactOwlCarousel>
								</div>
							</div>
						</div>{' '}
						{/* <!-- .product__gallery / end --> */}
						{/* <!-- .product__info --> */}
						<div className='product__info'>
							<div className='product__wishlist-compare'>
								<button
									type='button'
									className='btn btn-sm btn-light btn-svg-icon'
									data-toggle='tooltip'
									data-placement='right'
									title='Wishlist'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth={1.5}
										stroke='currentColor'
										style={{ width: '16px' }}>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z'
										/>
									</svg>
								</button>{' '}
								<button
									type='button'
									className='btn btn-sm btn-light btn-svg-icon'
									data-toggle='tooltip'
									data-placement='right'
									title='Compare'>
									<svg width='16px' height='16px'>
										<use href='images/sprite.svg#compare-16'></use>
									</svg>
								</button>
							</div>
							<h1 className='product__name'>Latest dubai cheery floral pant tops Multicolor.</h1>
							<div className='product__rating'>
								<div className='product__rating-stars'>
									<div className='rating'>
										<div className='rating__body'>
											<svg
												className='rating__star rating__star--active'
												width='13px'
												height='12px'>
												<g className='rating__fill'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														fill='none'
														viewBox='0 0 24 24'
														strokeWidth={1.5}
														stroke='currentColor'
														className='w-6 h-6'>
														<path
															strokeLinecap='round'
															strokeLinejoin='round'
															d='M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z'
														/>
													</svg>
												</g>
												<g className='rating__stroke'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														viewBox='0 0 24 24'
														fill='currentColor'
														className='w-6 h-6'>
														<path
															fillRule='evenodd'
															d='M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z'
															clipRule='evenodd'
														/>
													</svg>
												</g>
											</svg>
											<div className='rating__star rating__star--only-edge rating__star--active'>
												<div className='rating__fill'>
													<div className='fake-svg-icon'></div>
												</div>
												<div className='rating__stroke'>
													<div className='fake-svg-icon'></div>
												</div>
											</div>
											<svg
												className='rating__star rating__star--active'
												width='13px'
												height='12px'>
												<g className='rating__fill'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														fill='none'
														viewBox='0 0 24 24'
														strokeWidth={1.5}
														stroke='currentColor'
														className='w-6 h-6'>
														<path
															strokeLinecap='round'
															strokeLinejoin='round'
															d='M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z'
														/>
													</svg>
												</g>
												<g className='rating__stroke'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														viewBox='0 0 24 24'
														fill='currentColor'
														className='w-6 h-6'>
														<path
															fillRule='evenodd'
															d='M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z'
															clipRule='evenodd'
														/>
													</svg>
												</g>
											</svg>
											<div className='rating__star rating__star--only-edge rating__star--active'>
												<div className='rating__fill'>
													<div className='fake-svg-icon'></div>
												</div>
												<div className='rating__stroke'>
													<div className='fake-svg-icon'></div>
												</div>
											</div>
											<svg
												className='rating__star rating__star--active'
												width='13px'
												height='12px'>
												<g className='rating__fill'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														fill='none'
														viewBox='0 0 24 24'
														strokeWidth={1.5}
														stroke='currentColor'
														className='w-6 h-6'>
														<path
															strokeLinecap='round'
															strokeLinejoin='round'
															d='M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z'
														/>
													</svg>
												</g>
												<g className='rating__stroke'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														viewBox='0 0 24 24'
														fill='currentColor'
														className='w-6 h-6'>
														<path
															fillRule='evenodd'
															d='M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z'
															clipRule='evenodd'
														/>
													</svg>
												</g>
											</svg>
											<div className='rating__star rating__star--only-edge rating__star--active'>
												<div className='rating__fill'>
													<div className='fake-svg-icon'></div>
												</div>
												<div className='rating__stroke'>
													<div className='fake-svg-icon'></div>
												</div>
											</div>
											<svg
												className='rating__star rating__star--active'
												width='13px'
												height='12px'>
												<g className='rating__fill'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														fill='none'
														viewBox='0 0 24 24'
														strokeWidth={1.5}
														stroke='currentColor'
														className='w-6 h-6'>
														<path
															strokeLinecap='round'
															strokeLinejoin='round'
															d='M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z'
														/>
													</svg>
												</g>
												<g className='rating__stroke'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														viewBox='0 0 24 24'
														fill='currentColor'
														className='w-6 h-6'>
														<path
															fillRule='evenodd'
															d='M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z'
															clipRule='evenodd'
														/>
													</svg>
												</g>
											</svg>
											<div className='rating__star rating__star--only-edge rating__star--active'>
												<div className='rating__fill'>
													<div className='fake-svg-icon'></div>
												</div>
												<div className='rating__stroke'>
													<div className='fake-svg-icon'></div>
												</div>
											</div>
											<svg className='rating__star' width='13px' height='12px'>
												<g className='rating__fill'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														fill='none'
														viewBox='0 0 24 24'
														strokeWidth={1.5}
														stroke='currentColor'
														className='w-6 h-6'>
														<path
															strokeLinecap='round'
															strokeLinejoin='round'
															d='M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z'
														/>
													</svg>
												</g>
												<g className='rating__stroke'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														viewBox='0 0 24 24'
														fill='currentColor'
														className='w-6 h-6'>
														<path
															fillRule='evenodd'
															d='M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z'
															clipRule='evenodd'
														/>
													</svg>
												</g>
											</svg>
											<div className='rating__star rating__star--only-edge'>
												<div className='rating__fill'>
													<div className='fake-svg-icon'></div>
												</div>
												<div className='rating__stroke'>
													<div className='fake-svg-icon'></div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='product__rating-legend'>
									<Link to='/'>7 Reviews</Link>
									<span>/</span>
									<Link to='/'>Write A Review</Link>
								</div>
							</div>
							<div className='product__description'>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit.
								Curabitur ornare, mi in ornare elementum, libero nibh lacinia
								urna, quis convallis lorem erat at purus. Maecenas eu varius
								nisi.
							</div>
							<ul className='product__features'>
								<li>Speed: 750 RPM</li>
								<li>Power Source: Cordless-Electric</li>
								<li>Battery Cell Type: Lithium</li>
								<li>Voltage: 20 Volts</li>
								<li>Battery Capacity: 2 Ah</li>
							</ul>
							<ul className='product__meta'>
								<li className='product__meta-availability'>
									Availability: <span className='text-success'>In Stock</span>
								</li>
								<li>
									Brand: <Link to='/'>Wakita</Link>
								</li>
								<li>SKU: 83690/32</li>
							</ul>
						</div>
						{/* <!-- .product__info / end --> */}
						{/* <!-- .product__sidebar --> */}
						<div className='product__sidebar'>
							<div className='product__availability'>
								Availability: <span className='text-success'>In Stock</span>
							</div>
							<div className='product__prices'>$1,499.00</div>
							{/* <!-- .product__options --> */}
							<form className='product__options'>
								<div className='form-group product__option'>
									<label className='product__option-label'>Color</label>
									<div className='input-radio-color'>
										<div className='input-radio-color__list'>
											<label
												className='input-radio-color__item input-radio-color__item--white'
												style={{ color: '#fff' }}
												data-toggle='tooltip'
												title='White'>
												<input type='radio' name='color' /> <span></span>
											</label>{' '}
											<label
												className='input-radio-color__item'
												style={{ color: '#ffd333' }}
												data-toggle='tooltip'
												title='Yellow'>
												<input type='radio' name='color' /> <span></span>
											</label>{' '}
											<label
												className='input-radio-color__item'
												style={{ color: '#ff4040' }}
												data-toggle='tooltip'
												title='Red'>
												<input type='radio' name='color' />
												<span></span>
											</label>{' '}
											<label
												className='input-radio-color__item input-radio-color__item--disabled'
												style={{ color: '#4080ff' }}
												data-toggle='tooltip'
												title='Blue'>
												<input type='radio' name='color' disabled='disabled' />
												<span></span>
											</label>
										</div>
									</div>
								</div>
								<div className='form-group product__option'>
									<label className='product__option-label'>Material</label>
									<div className='input-radio-label'>
										<div className='input-radio-label__list'>
											<label>
												<input type='radio' name='material' />{' '}
												<span>Metal</span>
											</label>{' '}
											<label>
												<input type='radio' name='material' /> <span>Wood</span>
											</label>
											<label>
												<input
													type='radio'
													name='material'
													disabled='disabled'
												/>
												<span>Plastic</span>
											</label>
										</div>
									</div>
								</div>
								<div className='form-group product__option'>
									<label className='product__option-label' htmlFor='product-quantity'>
										Quantity
									</label>
									<div className='product__actions'>
										<div className='product__actions-item'>
											<div className='input-number product__quantity'>
												<input
													id='product-quantity'
													className='input-number__input form-control form-control-lg'
													type='number'
													min='1'
													defaultValue='1'
												/>
												<div className='input-number__add'></div>
												<div className='input-number__sub'></div>
											</div>
										</div>
										<div className='product__actions-item product__actions-item--addtocart'>
											<button className='btn btn-primary btn-lg'>
												Add to cart
											</button>
										</div>
										<div className='product__actions-item product__actions-item--wishlist'>
											<button
												type='button'
												className='btn btn-secondary btn-svg-icon btn-lg'
												data-toggle='tooltip'
												title='Wishlist'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													fill='none'
													viewBox='0 0 24 24'
													strokeWidth={1.5}
													stroke='currentColor'
													style={{ width: '20px' }}>
													<path
														strokeLinecap='round'
														strokeLinejoin='round'
														d='M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z'
													/>
												</svg>
											</button>
										</div>
									</div>
								</div>
							</form>
							{/* <!-- .product__options / end --> */}
						</div>
						{/* <!-- .product__end --> */}
						<div className='product__footer'>
							<div className='product__tags tags'>
								<div className='tags__list'>
									<Link to='/'>Mounts</Link> <Link to='/'>Electrodes</Link>{' '}
									<Link to='/'>Chainsaws</Link>
								</div>
							</div>
							<div className='product__share-links share-links'>
								<ul className='share-links__list'>
									<li className='share-links__item share-links__item--type--like'>
										<Link to='/'>Like</Link>
									</li>
									<li className='share-links__item share-links__item--type--tweet'>
										<Link to='/'>Tweet</Link>
									</li>
									<li className='share-links__item share-links__item--type--pin'>
										<Link to='/'>Pin It</Link>
									</li>
									<li className='share-links__item share-links__item--type--counter'>
										<Link to='/'>4K</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				
					<ProductDetailsTab />
			</div>
			<Footer />
		</div>
	);
};

export default ProductDetails;
